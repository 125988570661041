import { parsePhoneNumber } from "libphonenumber-js";
import * as yup from "yup";

export const ProjectSchema = yup.object().shape({
  projectName: yup.string().required("Project Name is required"),
  projectDescription: yup.string().required("Project Description is required"),
  projectStartMonth: yup.string().required("Project Start Month is required"),
  projectEndMonth: yup.string().required("Project End Month is required"),
  projectStartYear: yup.string().required("Project Start Year is required"),
  projectEndYear: yup.string().required("Project End Year is required"),
});

export const EducationSchema = yup.object().shape({
  qualification: yup.string().required("Qualification is required"),
  // courseName: yup.string().required("Project Description is required"),
  organizationName: yup.string().required("School/Collage/Institute Name is required"),
  courseStartYear: yup.string().required("Course Start Year is required"),
  courseEndYear: yup.string().required("Course End Year is required"),
  courseType: yup.string().required("Course Type is required"),
});

const isValidLink = (link) => {
  // Check if it's a valid URL
  if (/^(ftp|http|https):\/\/[^ "]+$/.test(link)) {
    return true;
  }

  // Check if it's a valid PDF file (you can adjust the regex based on your requirements)
  if (/\.pdf$/.test(link)) {
    return true;
  }

  return false;
};

// Function to check if the file size is below 2MB
const isFileSizeBelow2MB = (file) => {
  if (!file) return false;
  return file.size <= 2 * 1024 * 1024; // 2MB in bytes
};

export const RegisterSchema = yup.object().shape({
  fullName: yup
    .string()
    .required("Fullname is required")
    .min(3, "Fullname must be at least 3 characters")
    .max(50, "Fullname cannot exceed 50 characters"),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
      "Password must include at least one lowercase letter, one uppercase letter, one number, and one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Password must be match"),
});

export const LoginSchema = yup.object().shape({
  email: yup.string().email("Email is not valid").required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const EmailResetPasswordLinkScheme = yup.object().shape({
  email: yup.string().email("Email is not valid").required("Email is required"),
});

export const DataCollectSchema = yup.object().shape({
  profile: yup
    .mixed()
    .required("Image is required")
    .test("fileType", "File must be an image (JPEG, JPG, PNG)", (value) => {
      console.log(value, "value-----------------");
      if (!value) return false; // Fail validation if no file is provided
      const extension = value.name.split(".").pop().toLowerCase();
      return ["jpg", "jpeg", "png"].includes(extension);
    }),
  // profile: yup
  //   .mixed()
  //   .test("fileType", "File must be an image (JPEG, JPG, PNG)", (value) => {
  //     console.log(value, "value-----------------");
  //     if (!value) return true; // Pass validation if no file is provided

  //     const extension = value.name.split(".").pop().toLowerCase();
  //     return ["jpg", "jpeg", "png"].includes(extension);
  //   }).default(null),

  fullName: yup.string().required("Fullname is required"),
  projects: yup.array().of(ProjectSchema).default([]),
  educations: yup.array().of(EducationSchema).default([]),
  salaryExpectation: yup.number().typeError("Salary Expectation is required"),
  profileSummary: yup
    .string()
    .min(150, "Profile summary should be at least 150 letters")
    .max(1000, "Profile summary should not exceed 1000 letters").required("Profile summary is required"),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field"),
  gender: yup.string().required("Gender is required"),
  noticePeriod: yup
    .string()
    .required("Notice Period is required"),
  // .default("Immediate Joiner"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .test("isValidPhoneNumber", "Invalid phone number", (value) => {
      try {
        const phoneNumber = parsePhoneNumber(value, "IN"); // 'IN' is the country code for India, adjust as needed
        return phoneNumber && phoneNumber.isValid();
      } catch (error) {
        return false;
      }
    }),
  city: yup
    .string()
    .required("Current city is required")
    .default("Pondicherry"),
  area: yup.string().required("Area is required"),
  street: yup.string().required("Street is required"),
  pincode: yup
    .number()
    .required("Pincode is required")
    .typeError("Pincode is required"),
  workStatus: yup.string().required("Work Status is required"),
  dob: yup
    .date()
    .max(new Date(), "Date of birth cannot be in the future")
    .required("Date of birth is required"),
  skills: yup.array().min(1).required("array is required"),
  speakingLanguages: yup.array().min(1).required("array is required"),
  resume: yup
    .mixed()
    .required("PDF file is required")
    .test("fileType", "File must be a PDF", (value) => {
      // Fail validation if no file is provided
      if (!value) return false;

      // Check if the file type is PDF
      if (value.type !== "application/pdf") {
        return false;
      }

      return true;
    })
    .test("fileSize", "File size must be below 2 MB", (value) => {
      // Additional test for file size
      if (!value) return false; // Fail validation if no file is provided
      const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
      return value.size <= maxSize;
    }),
});

export const UpdateSchema = yup.object().shape({
  profile: yup
    .mixed()
    .test(
      "isLinkOrFile",
      "File must be an image (JPEG, JPG, PNG) or a valid link",
      (value) => {
        if (!value) return true; // Allow empty values
        if (typeof value === "string") {
          // If it's a string, assume it's a link
          // You can add custom validation logic for links here if needed
          return isValidLink(value); // Implement isValidLink function as needed
        } else {
          // If it's a File object, validate the file type
          const extension = value.name.split(".").pop().toLowerCase();
          return ["jpg", "jpeg", "png"].includes(extension);
        }
      }
    ),
  fullName: yup.string().required("Fullname is required"),
  linkedIn: yup.string(),
  projects: yup.array().of(ProjectSchema).default([]),
  educations: yup.array().of(EducationSchema).default([]),
  salaryExpectation: yup.number().typeError("Salary Expectation is required"),
  profileSummary: yup
    .string()
    .min(150, "Profile summary should be at least 150 letters")
    .max(350, "Profile summary should not exceed 350 letters"),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field"),
  gender: yup.string().required("Gender is must").default("male"),
  noticePeriod: yup
    .string()
    .required("Notice Period is must")
    .default("Immediate Joiner"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .test("isValidPhoneNumber", "Invalid phone number", (value) => {
      try {
        const phoneNumber = parsePhoneNumber(value, "IN"); // 'IN' is the country code for India, adjust as needed
        return phoneNumber && phoneNumber.isValid();
      } catch (error) {
        return false;
      }
    }),
  city: yup
    .string()
    .required("Current city is required")
    .default("Pondicherry"),
  area: yup.string().required("Area is required"),
  street: yup.string().required("Street is required"),
  pincode: yup
    .number()
    .required("Pincode is required")
    .typeError("Pincode is required"),
  workStatus: yup.string().required("Work Status is required"),
  dob: yup
    .date()
    .max(new Date(), "Date of birth cannot be in the future")
    .required("Date of birth is required"),
  skills: yup.array().min(1).required("array is required"),
  speakingLanguages: yup.array().min(1).required("array is required"),
  resume: yup
    .mixed()
    .required("Resume is required")
    .test("fileType", "File must be a PDF or a valid link", (value) => {
      if (!value) return false; // Fail validation if no file or link is provided
      const extension = value.name
        ? value.name.split(".").pop().toLowerCase()
        : null;
      return extension === "pdf" || isValidLink(value);
    }),
});

export const CreateNewPassword = yup.object().shape({
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(8, "New Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      "New Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),

  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export const SettingsPasswordUpdate = yup.object().shape({
  oldPassword: yup.string().required("Old Password is required"),
  // .min(8, 'Old Password must be at least 8 characters'),

  newPassword: yup
    .string()
    .required("New Password is required")
    .min(8, "New Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      "New Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),

  confirmPassword: yup
    .string()
    .required("Confirm New Password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});
