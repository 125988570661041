import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useRecruiterReactQuery } from "../../utils/RecruiterDataContext";
import { Link } from "react-router-dom";
import { MdOutlineManageAccounts } from "react-icons/md";
import { FaAngleRight } from "react-icons/fa";
import { TfiControlBackward } from "react-icons/tfi";

export function JPRSettingsLanding() {
  const { data, isLoading, error } = useRecruiterReactQuery();

  const { recruiter = {} } = data || {};

  console.log(recruiter.userId);

  return (
    <main className="my-3">
      <div className="col m-auto">
        <label className="fs-em-4" htmlFor="userId">
          User Id <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="text"
          name="userId"
          id="userId"
          value={recruiter.userId || ""}
          disabled
        />
      </div>
      <div className="col m-auto">
        <label className="fs-em-4" htmlFor="userId">
          Full Name <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="text"
          name="userId"
          id="userId"
          value={recruiter.fullName || ""}
          disabled
        />
      </div>

      <div className="col m-auto">
        <label className="fs-em-4" htmlFor="userId">
          Origanizational Email Id <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="text"
          name="userId"
          id="userId"
          value={recruiter.organizationEmail || ""}
          disabled
        />
      </div>
      <div className="col m-auto">
        <label className="fs-em-4" htmlFor="userId">
          Origanizational Name<span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="text"
          name="userId"
          id="userId"
          value={recruiter.organizationName || ""}
          disabled
        />
      </div>

      <h5 className="d-flex align-items-center fs-5 pe-auto">
        <MdOutlineManageAccounts className="fs-8" />
        &nbsp; Account Settings
      </h5>
      <Link
        to="update-password"
        className="d-flex align-items-center fs-5 pe-auto my-2"
      >
        Change your Password &nbsp; <FaAngleRight />
      </Link>
      <Link
        to="update-profile"
        className="d-flex align-items-center fs-5 pe-auto my-2"
      >
        Update your Profile &nbsp; <FaAngleRight />
      </Link>
    </main>
  );
}
