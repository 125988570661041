import { parsePhoneNumber } from "libphonenumber-js";
import * as yup from "yup";

export const LoginSchema = yup.object().shape({
  userId: yup.string().required("UserId is Mandatory"),
  password: yup.string().required("Password is incorrect"),
});

export const RegisterSchema = yup.object().shape({
  fullName: yup.string().required("Fullname is Mandatory"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .matches(/[0-9]/, "Phone Number must contain at least one digit"),
  // .matches(/^[0-9]{10}$/, "Phone Number must be 10 digits"),
  // countryCode: yup
  //   .string()
  //   .required("Phone Number is required")
  //   .matches(/^[0-9]{10}$/, "Phone Number must be 10 digits"),
  role: yup.string().required("Role/Title is Mandatory"),
  organizationName: yup.string().required("Organization Name is Mandatory"),
  organizationEmail: yup
    .string()
    .email("Email is not valid")
    .required("Email is Mandatory"),
  interestedIn: yup.array().min(1).required("Skill is required"),
  message: yup.string().notRequired(),
});

export const SettingsProfileUpdate = yup.object().shape({
  fullName: yup.string().required("Full Name is required"),
  role: yup.string().required("Role is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .test("isValidPhoneNumber", "Invalid phone number", (value) => {
      try {
        const phoneNumber = parsePhoneNumber(value, "IN"); // Adjust the country code as needed
        return phoneNumber && phoneNumber.isValid();
      } catch (error) {
        return false;
      }
    }),
  interestedIn: yup
    .array()
    .min(1, "At least one skill is required")
    .required("Skills are required"),
  countryCode: yup.string(),
});

export const SettingsPasswordUpdate = yup.object().shape({
  oldPassword: yup
    .string()
    .required('Old Password is required'),
  // .min(8, 'Old Password must be at least 8 characters'),

  newPassword: yup
    .string()
    .required('New Password is required')
    .min(8, 'New Password must be at least 8 characters')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'New Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),

  confirmPassword: yup
    .string()
    .required('Confirm New Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
