import styled from "@emotion/styled";
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  createTheme,
} from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ThemeProvider } from "@emotion/react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    typography: {
      fontSize: "20px", // Adjust the font size here
    },
    fontSize: "5rem",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function Alert({ state, children }) {
  const [open, setOpen] = state;

  const theme = createTheme();

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, color: "red" }}
          id="customized-dialog-title"
          style={{ fontSize: "2rem" }}
        >
          Alert
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoCloseCircleOutline style={{ fontSize: "3rem" }} />
        </IconButton>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} sx={{ fontSize: "1.5rem" }}>
            Okay
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </ThemeProvider>
  );
}
