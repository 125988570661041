import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { RECRUITER_TOKEN_NAME } from "../common/constant";
import { LogoutAlertProvider } from "../JP-Recruiter/Context/LogoutAlertContext";
import { RecruiterDataProvider } from "./RecruiterDataContext";

export const RecruiterAuthorization = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(
    localStorage.getItem(RECRUITER_TOKEN_NAME)
  );

  useEffect(() => {
    setToken(localStorage.getItem(RECRUITER_TOKEN_NAME));
    console.log("app useEffect token", token);

    // Get the current location pathname
    const currentPathname = window.location.pathname;

    if (token && currentPathname === "/job-portal/recruiter") {
      // If token exists and current location is the root, navigate to the "profile" route
      navigate("home");
    }
  }, [token]);

  console.log("called");

  return token ? (
    <RecruiterDataProvider>
      <LogoutAlertProvider>
        <Outlet />
      </LogoutAlertProvider>
    </RecruiterDataProvider>
  ) : (
    <Navigate to="login" />
  );
};
