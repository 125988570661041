import React from "react";
import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  createTheme,
} from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ThemeProvider } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    typography: {
      fontSize: "20px", // Adjust the font size here
    },
    fontSize: "5rem",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function SuccessConfirmation({
  state,
  children,
  closeOnpress = false,
  navigateScreen = false,
}) {
  const navigate = useNavigate();

  const [open, setOpen] = state;

  const theme = createTheme();

  // console.log(children, "children");

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    setOpen(false);
    if (closeOnpress) navigate(-1);
    if (navigateScreen) navigateScreen();
  };

  // const handleBackdropClick = (event) => {
  //   // Prevent the click event from reaching the dialog
  //   event.stopPropagation();
  // };

  return (
    <ThemeProvider theme={theme}>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // backdrop="static"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Notification
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoCloseCircleOutline style={{ fontSize: "30px" }} />
        </IconButton>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} style={{ fontSize: "16px" }}>
            Okay
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </ThemeProvider>
  );
}
