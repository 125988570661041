import "./Style/CoverImageStyle.scss";
import React, { useEffect, useRef } from "react";
import CodingVideo from "../../Videos/OA_Ad.mp4";
import videoThum from "../../Videos/oa_thum.png";
import { AiFillPlayCircle, AiOutlinePauseCircle } from "react-icons/ai";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { GoMute, GoUnmute } from "react-icons/go";

export function CoverImage() {
  const navigate = useNavigate();
  const [sound, setSound] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    document.querySelector("#pause").style.display = "none";
    AOS.init();
  }, []);

  const play = () => {
    let play = document.querySelector("#play");
    let pause = document.querySelector("#pause");
    console.log(videoRef.current, "play");

    play.style.display = "none";
    pause.style.display = "block";

    videoRef.current.play();
  };

  const pause = () => {
    let play = document.querySelector("#play");
    let pause = document.querySelector("#pause");

    play.style.display = "block";
    pause.style.display = "none";

    console.log(videoRef.current, "pause");
    videoRef.current.pause();
  };

  const soundHandler = (flag) => {
    setSound(flag);
  };

  return (
    <div className="coverImg">
      <div className="bgClr">
        <div className="row">
          <div
            className="col"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <h1>
              Instil the passion for coding in you through our state-of-the-art
              training
            </h1>
            <p>
              Upskill yourself through practice-oriented training, learn
              in-demand skills, and expand your career opportunities.
            </p>
            <div
              className="enrollNowBtn"
              onClick={() => {
                navigate("/onlinecourses/");
              }}
            >
              Enroll Now
            </div>
          </div>
          <div className="col">
            <div className="video">
              <div className="bg-Color">
                <span id="play">
                  <AiFillPlayCircle className="icon" onClick={play} />
                </span>
                <span id="pause">
                  <AiOutlinePauseCircle className="icon" onClick={pause} />
                </span>

                {/* {sound ? (
                  <span
                    className="sound"
                    id="sound-on"
                    onClick={() => soundHandler(true)}
                  >
                    <GoMute />
                  </span>
                ) : (
                  <span
                    className="sound"
                    id="sound-off"
                    onClick={() => soundHandler(false)}
                  >
                    <GoUnmute />
                  </span>
                )} */}
              </div>
              <video
                ref={videoRef}
                controls={false}
                className="video"
                loop={false}
                autoPlay={false}
                muted={sound}
                poster={videoThum}
              >
                <source src={CodingVideo} />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
