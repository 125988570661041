export function Title({ page, overAllPages }) {
  return (
    <section className="d-flex justify-content-between">
      <article>All Canditates</article>
      <article className="f-3 clr-primary">
        Showing {page} - {overAllPages} results
      </article>
    </section>
  );
}
