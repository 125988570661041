import "./_jp_recruiter_register.scss";
import React, { useState } from "react";
import Rectangles from "../../../Image/rectangles.svg";
import CirclePattern from "../../../Image/circlepattern.svg";
import Select from "react-select";
import { Appbar } from "../../../Appbar/Appbar";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterSchema } from "../validations";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { ErrorMessage } from "@hookform/error-message";
import { jobPortal } from "../../../..";
import { Alert } from "../JPR-Home/Alert";
import { Typography } from "@mui/material";
import { SuccessConfirmation } from "../../Components/SuccessConfirmation";
import { useNavigate } from "react-router-dom";
import { IT_SKILLS } from "../../common/constant";

export function JPRecruiterRegister() {
  const [isRegisterSuccess, setRegisterSuccess] = useState(false);
  const [iserror, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
  } = useForm({ resolver: yupResolver(RegisterSchema) });

  // Watch for changes in the phoneNumber field
  const watchPhoneNumber = watch("phoneNumber");

  const navigate = useNavigate();

  // Handle the onChange event of the PhoneInput component
  const handlePhoneChange = (value, data) => {
    setValue("phoneNumber", value);
    setValue("countryCode", `+${data?.dialCode}` || "");
  };

  const options = [
    { value: "python", label: "python" },
    { value: "javascript", label: "javascript" },
    { value: "java", label: "java" },
    { value: "ruby", label: "ruby" },
    { value: "flutter", label: "flutter" },
  ];

  // Define a function to generate the default values for the schema
  const getDefaultValues = () => {
    reset({
      fullName: "",
      phoneNumber: "",
      organizationEmail: "",
      organizationName: "",
      interestedIn: [],
      role: "",
      message: "",
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    const phoneNumberWithoutCode = data.phoneNumber.replace(
      `${data?.countryCode.slice(1)}`,
      ""
    );
    console.log(phoneNumberWithoutCode, "phoneNumberWithoutCode");
    console.log(data, "data received");
    const newData = {
      ...data,
      phoneNumber: phoneNumberWithoutCode,
    };

    try {
      const response = await jobPortal.post("recruiter/new-request", newData);
      console.log(response.data, "then");
      setRegisterSuccess(true);

      getDefaultValues();
    } catch (error) {
      console.log(error.response);

      if (error.response && error.response.status === 409) {
        setErrorMessage(error.response.data.message);
        setError(true);
        getDefaultValues();
      }
    }
  });

  return (
    <>
      <Appbar />
      <main className="JobPortalReport">
        <img
          src={Rectangles}
          alt="not found"
          style={{ position: "fixed", zIndex: "-1" }}
        />
        <img
          src={CirclePattern}
          alt="not found"
          style={{ position: "fixed", bottom: "0px", zIndex: "-1" }}
        />
        <img
          src={CirclePattern}
          alt="not found"
          style={{ position: "fixed", top: "0px", right: "0px", zIndex: "-1" }}
        />
        <main className="container">
          <p className="title text-center f-14 m-auto">
            Our Admin will reach out to you within 2 business days! Please stay
            tuned &#9995;
          </p>
          <form className="flex-col">
            <section className="row-wrapper flex-row justify-content-between my-1">
              <div className="input-wrapper flex-col">
                <label htmlFor="" className="f-7 mb-2">
                  Full Name <span>*</span>
                </label>
                <input
                  className="px-1"
                  type="text"
                  name="fullName"
                  {...register("fullName")}
                />
                <p className="text-red fs-3">{errors.fullName?.message}</p>
              </div>
              <div className="input-wrapper flex-col">
                <label htmlFor="" className="f-7 mb-2">
                  Your Role / Title <span>*</span>
                </label>
                <input
                  className="px-1"
                  type="text"
                  name="role"
                  {...register("role")}
                />
                <p className="text-red fs-3">{errors.role?.message}</p>
              </div>
            </section>
            <section className="row-wrapper flex-row justify-content-between my-1">
              <div className="input-wrapper flex-col">
                <label htmlFor="" className="f-7 mb-2">
                  Your Organization Name
                  <span>*</span>
                </label>
                <input
                  className="px-1"
                  type="text"
                  name="organizationName"
                  {...register("organizationName")}
                />
                <p className="text-red fs-3">
                  {errors.organizationName?.message}
                </p>
              </div>
              <div className="input-wrapper flex-col">
                <label htmlFor="" className="f-7 mb-2">
                  Organization Email <span>*</span>
                </label>
                <input
                  className="px-1"
                  type="text"
                  name="organizationEmail"
                  {...register("organizationEmail")}
                />
                <p className="text-red fs-3">
                  {errors.organizationEmail?.message}
                </p>
              </div>
            </section>

            <section className="row-wrapper flex-row justify-content-between  my-3">
              <div className="input-wrapper flex-col">
                <label htmlFor="" className="f-7 mb-2">
                  Phone Number <span>*</span>
                </label>
                {/* <PhoneInput
                  inputProps={{
                    name: 'phoneNumber',
                    required: true,
                    autoFocus: true
                  }}
                  country={'in'}
                  placeholder="Enter phone number"
                  {...register("phoneNumber")}
                // value={value}
                // onChange={setValue}
                /> */}
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                        autoFocus: true,
                      }}
                      country={"in"}
                      placeholder="Enter phone number"
                      value={watchPhoneNumber}
                      onChange={(value, data) => handlePhoneChange(value, data)}
                    />
                  )}
                />
                <p className="text-red fs-3">{errors.phoneNumber?.message}</p>
                {/* <ErrorMessage
                  errors={errors}
                  name="phoneNumber"
                  render={() => (
                    <p
                      style={{
                        color: "red",
                        fontSize: "15px",
                        paddingBottom: "10px",
                      }}
                    >
                      phoneNumber is required
                    </p>
                  )}
                /> */}
              </div>
            </section>
            <section className="row-wrapper flex-row justify-content-between  my-3">
              <div className="input-wrapper flex-col">
                <label htmlFor="" className="f-7 mb-2">
                  You are interested in <span>*</span>
                </label>
                <Controller
                  name="interestedIn"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className="basic-multi-select fs-5 my-2"
                      isMulti
                      value={IT_SKILLS.filter((option) =>
                        field.value?.includes(option.value)
                      )} // Pre-select the values
                      onChange={(selectedOptions) =>
                        field.onChange(
                          selectedOptions.map((option) => option.value)
                        )
                      }
                      options={IT_SKILLS}
                      classNamePrefix="select"
                    />
                  )}
                  {...register("interestedIn")}
                />

                <ErrorMessage
                  errors={errors}
                  name="interestedIn"
                  render={() => (
                    <p className="text-red fs-3 my-1">Skills is required</p>
                  )}
                />
              </div>
            </section>

            <section className="row-wrapper flex-row my-2">
              <div className="textarea-wrapper flex-col">
                <label htmlFor="" className="f-7 mb-2">
                  Message (optional)
                </label>
                <textarea
                  className="f-5 p-2"
                  rows="10"
                  cols=""
                  placeholder="Anything else you would like to share"
                  name="message"
                  {...register("message")}
                ></textarea>
              </div>
            </section>

            <section className="submit-btn center f-4 my-6" onClick={onSubmit}>
              Submit
            </section>
          </form>
        </main>
      </main>

      <Alert state={[iserror, setError]}>
        <Typography gutterBottom sx={{ fontSize: "1.8rem" }}>
          {errorMessage}
        </Typography>
      </Alert>

      <SuccessConfirmation state={[isRegisterSuccess, setRegisterSuccess]}>
        <Typography
          color="green"
          gutterBottom
          sx={{ fontSize: "1.6rem", fontWeight: "bold" }}
        >
          Your profile has been successfully updated 😊
        </Typography>
        <Typography gutterBottom style={{ fontSize: "1.5rem" }}>
          Your profile is currently under review by our admin team. Please wait
          for approval. You may receive a call for further guidance.
        </Typography>
        <Typography gutterBottom style={{ fontSize: "1.5rem" }}>
          Happy recruiting!
        </Typography>
      </SuccessConfirmation>
    </>
  );
}
