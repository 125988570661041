import { AppbarCombo } from "../AppbarCombo/AppbarCombo";
import { SideNavbar } from "../SideNavbar/SideNavbar";
import { JPW } from "./JP-Welcome/JPW";

export function JPLandingPage() {
  return (
    <>
      <SideNavbar />
      <AppbarCombo />
      <JPW />
    </>
  );
}
