import { HiOutlineHome } from "react-icons/hi";
import { CiSettings } from "react-icons/ci";
import logo from "../../../Image/login_logo.svg";
import { useLogout } from "../Context/LogoutAlertContext";
import { NavLink } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";

export function JPRecruiterHomeSidebar() {
  const { openLogoutAlert } = useLogout();

  const closeSidebar = () => {
    const doc = document;
    const sidebar = doc.querySelector(".sidebar-active");
    if (sidebar) sidebar.className = "sidebar";
  };

  return (
    <aside className="sidebar">
      <article className="logo d-flex justify-content-center align-items-center">
        <img src={logo} alt="logo" width="100%" />
      </article>

      <NavLink
        onClick={closeSidebar}
        to="/job-portal/recruiter/dashboard/home"
        className="m-3 d-flex align-items-center clr-dark pe-auto"
        style={({ isActive, isPending, isTransitioning }) => {
          return {
            textDecoration: "none",
            fontWeight: isActive ? "bold" : "",
            color: isActive ? "#0081f2" : "black",
            viewTransitionName: isTransitioning ? "slide" : "",
          };
        }}
      >
        <HiOutlineHome className="f-5" />
        <span className="ml-1 f-5">Home</span>
      </NavLink>

      <NavLink
        onClick={closeSidebar}
        to="/job-portal/recruiter/dashboard/settings"
        className="m-3 d-flex align-items-center clr-dark pe-auto"
        style={({ isActive, isPending, isTransitioning }) => {
          return {
            textDecoration: "none",
            fontWeight: isActive ? "bold" : "",
            color: isActive ? "#0081f2" : "black",
            viewTransitionName: isTransitioning ? "slide" : "",
          };
        }}
      >
        <CiSettings className="f-5" />
        <span className="ml-1 f-5">Settings</span>
      </NavLink>

      <p
        className="m-3 d-flex align-items-center clr-dark pe-auto"
        onClick={openLogoutAlert}
      >
        <CiSettings className="f-5" />
        <span className="ml-1 f-5">Logout</span>
      </p>

      <IoArrowBackCircleOutline className="back-icon" onClick={closeSidebar} />
    </aside>
  );
}
