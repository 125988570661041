import "./Style/_AppbarStyle.scss";
import React, { useState } from "react";
import { openSideNavbar } from "../Functions/SidebarFunction";
import { isSelect } from "../Functions/RouterDomActiveFunction";
import Logo from "../Image/logo.svg";
import { IoMenuOutline } from "react-icons/io5";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { FloatingActionButton } from "../FloatingActionButton/FloatingActionButton";
import { scrollPosition } from "../Functions/ScrollPosition";
// import ChatBotBtn from "../FloatingActionButton/ChatBotBtn";

export function Appbar() {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));

  // function logoutLogin() {
  //   if (token) {
  //     localStorage.removeItem("token");
  //     setToken(undefined);
  //     navigate("/");
  //   } else {
  //     navigate("/login");
  //   }
  //   scrollPosition();
  // }

  return (
    <nav className="appbar">
      <FloatingActionButton />
      {/* <ChatBotBtn /> */}
      <div className="logoPortion" onClick={() => navigate("/")}>
        <img src={Logo} alt="" />
      </div>
      <div className="routingPortion">
        <NavLink
          style={isSelect}
          className="textDecoration"
          to="/"
          onClick={scrollPosition}
        >
          Home
        </NavLink>
        <NavLink
          style={isSelect}
          className="textDecoration"
          to="/aboutus"
          onClick={scrollPosition}
        >
          About Us
        </NavLink>
        <NavLink
          style={isSelect}
          className="textDecoration"
          to="/services"
          onClick={scrollPosition}
        >
          Services
        </NavLink>
        <Link
          // to="/offlinecourses"
          // style={isSelect}
          id="courses"
          className="textDecoration"
        >
          Courses
          {/* <span>&nbsp; &#x25BC;</span> */}
          <div>
            <Link
              className="dropDownItem"
              to="/onlinecourses"
              onClick={scrollPosition}
            >
              Online Courses
            </Link>
            <Link
              className="dropDownItem"
              to="/offlinecourses"
              onClick={scrollPosition}
            >
              Offline Courses
            </Link>
          </div>
        </Link>

        <NavLink
          style={isSelect}
          className="textDecoration"
          to="https://placement.oceanacademy.co.in/"
          onClick={scrollPosition}
        >
          Placement
        </NavLink>
        <NavLink
          style={isSelect}
          className="textDecoration"
          to="/job-portal"
          onClick={scrollPosition}
        >
          Job Portal
        </NavLink>

        <NavLink
          style={isSelect}
          className="textDecoration"
          to="/contactus"
          onClick={scrollPosition}
        >
          Contact Us
        </NavLink>

        {token ? (
          <div
            onClick={() => navigate("/dashboard/mycourses/")}
            className="loginBtn center"
          >
            <span>Classroom</span>
          </div>
        ) : (
          <div onClick={() => navigate("/login")} className="loginBtn center">
            <span>Login</span>
          </div>
        )}

        {/* <div onClick={logoutLogin} className="loginBtn center">
           <span>Logout</span> : <span>Login</span>
        </div> */}
      </div>
      <div className="menuBtn" onClick={openSideNavbar}>
        <IoMenuOutline
          style={{
            fontSize: "30px",
            color: "white",
            backgroundColor: "rgb(32, 151, 243)",
            boxShadow: "2px 2px 20px 1px #2098f33b",
          }}
        />
      </div>
      <div className="tracking-line"></div>
    </nav>
  );
}
