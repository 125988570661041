import React from "react";
import "./_jpr_home.scss";
import { JPRecruiterHomeSidebar } from "./JPR_HomeSidebar";
import { Outlet } from "react-router-dom";
import { Header } from "./Header";
import { LogoutAlert } from "../../Components/LogoutAlert";

export function JPRecruiterDashboard() {
  return (
    <main className="jobportal-home d-flex">
      <JPRecruiterHomeSidebar />
      {/* <JPRecruiterHomeContent /> */}
      <main className="content">
        <Header />
        <section className="wrapper">
          <Outlet />
        </section>
      </main>
      <LogoutAlert />
    </main>
  );
}
