export const RECRUITER_TOKEN_NAME = "__r__userId";
export const CANDIDATE_TOKEN_NAME = "candidate_userId";

export const speakingLanguages = [
  { value: "Tamil", label: "Tamil" },
  { value: "English", label: "English" },
  { value: "French", label: "French" },
  { value: "Urdu", label: "Urdu" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Hindi", label: "Hindi" },
  { value: "Marathi", label: "Marathi" },
  { value: "Telugu", label: "Telugu" },
  { value: "Spanish", label: "Spanish" },
  { value: "Mandarin", label: "Mandarin" },
  { value: "Arabic", label: "Arabic" },
  { value: "Bengali", label: "Bengali" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Russian", label: "Russian" },
  { value: "Japanese", label: "Japanese" },
  { value: "German", label: "German" },
  { value: "Italian", label: "Italian" },
  { value: "Dutch", label: "Dutch" },
  { value: "Turkish", label: "Turkish" },
  { value: "Korean", label: "Korean" },
  { value: "Vietnamese", label: "Vietnamese" },
  { value: "Thai", label: "Thai" },
  { value: "Indonesian", label: "Indonesian" },
  { value: "Malay", label: "Malay" },
  { value: "Tagalog", label: "Tagalog" },
  { value: "Swahili", label: "Swahili" },
  { value: "Hausa", label: "Hausa" },
  { value: "Yoruba", label: "Yoruba" },
  { value: "Igbo", label: "Igbo" },
  { value: "Zulu", label: "Zulu" },
  { value: "Afrikaans", label: "Afrikaans" },
  { value: "Farsi", label: "Farsi" },
  { value: "Hebrew", label: "Hebrew" },
  { value: "Greek", label: "Greek" },
  { value: "Polish", label: "Polish" },
  { value: "Swedish", label: "Swedish" },
  { value: "Norwegian", label: "Norwegian" },
  { value: "Danish", label: "Danish" },
  { value: "Finnish", label: "Finnish" },
  { value: "Hungarian", label: "Hungarian" },
  { value: "Czech", label: "Czech" },
  { value: "Slovak", label: "Slovak" },
  { value: "Romanian", label: "Romanian" },
  { value: "Bulgarian", label: "Bulgarian" },
  { value: "Serbian", label: "Serbian" },
  { value: "Croatian", label: "Croatian" },
  { value: "Slovenian", label: "Slovenian" },
  { value: "Bosnian", label: "Bosnian" },
  { value: "Albanian", label: "Albanian" },
  { value: "Macedonian", label: "Macedonian" },
  { value: "Montenegrin", label: "Montenegrin" },
  { value: "Latvian", label: "Latvian" },
  { value: "Lithuanian", label: "Lithuanian" },
  { value: "Estonian", label: "Estonian" },
  { value: "Maltese", label: "Maltese" },
  { value: "Icelandic", label: "Icelandic" },
  { value: "Greenlandic", label: "Greenlandic" },
  { value: "Faroese", label: "Faroese" },
  { value: "Welsh", label: "Welsh" },
  { value: "Irish", label: "Irish" },
  { value: "Scottish Gaelic", label: "Scottish Gaelic" },
  { value: "Breton", label: "Breton" },
  { value: "Corsican", label: "Corsican" },
  { value: "Basque", label: "Basque" },
  { value: "Catalan", label: "Catalan" },
  { value: "Galician", label: "Galician" },
  { value: "Asturian", label: "Asturian" },
  { value: "Aragonese", label: "Aragonese" },
  { value: "Occitan", label: "Occitan" },
  { value: "Provencal", label: "Provencal" },
  { value: "Ligurian", label: "Ligurian" },
  { value: "Venetian", label: "Venetian" },
  { value: "Neapolitan", label: "Neapolitan" },
  { value: "Sicilian", label: "Sicilian" },
  { value: "Romansh", label: "Romansh" },
  { value: "Ladin", label: "Ladin" },
  { value: "Friulian", label: "Friulian" },
  { value: "Sardinian", label: "Sardinian" },
  { value: "Maltese", label: "Maltese" },
  { value: "Tibetan", label: "Tibetan" },
  { value: "Uighur", label: "Uighur" },
  { value: "Mongolian", label: "Mongolian" },
  { value: "Kazakh", label: "Kazakh" },
  { value: "Kyrgyz", label: "Kyrgyz" },
  { value: "Turkmen", label: "Turkmen" },
  { value: "Uzbek", label: "Uzbek" },
  { value: "Tajik", label: "Tajik" },
  { value: "Georgian", label: "Georgian" },
  { value: "Armenian", label: "Armenian" },
  { value: "Azerbaijani", label: "Azerbaijani" },
  { value: "Chechen", label: "Chechen" },
  { value: "Ingush", label: "Ingush" },
  { value: "Chuvash", label: "Chuvash" },
  { value: "Tatar", label: "Tatar" },
  { value: "Bashkir", label: "Bashkir" },
];

const currentYear = new Date().getFullYear() + 4;
const startYear = 2000;

export const yearsArray = Array.from(
  { length: currentYear - startYear + 1 },
  (_, index) => startYear + index
);

export const monthsArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const qualificationsArray = [
  "10th",
  "12th",
  "Diploma",
  "UG",
  "PG",
  "PHD",
];

export const courseTypesArray = ["Full Time", "Part Time", "Correspondence"];

export const IT_SKILLS = [
  { value: "html", label: "HTML" },
  { value: "css", label: "CSS" },
  { value: "javascript", label: "JavaScript" },
  { value: "react", label: "React.js" },
  { value: "angular", label: "Angular" },
  { value: "vue", label: "Vue.js" },
  { value: "nodejs", label: "Node.js" },
  { value: "express", label: "Express.js" },
  { value: "python", label: "Python" },
  { value: "django", label: "Django" },
  { value: "java", label: "Java" },
  { value: "spring", label: "Spring Framework" },
  { value: "ruby", label: "Ruby" },
  { value: "rails", label: "Ruby on Rails" },
  { value: "php", label: "PHP" },
  { value: "laravel", label: "Laravel" },
  { value: "csharp", label: "C#" },
  { value: "dotnet", label: ".NET" },
  { value: "sql", label: "SQL" },
  { value: "mongodb", label: "MongoDB" },
  { value: "mysql", label: "MySQL" },
  { value: "git", label: "Git" },
  { value: "docker", label: "Docker" },
  { value: "kubernetes", label: "Kubernetes" },
  { value: "aws", label: "Amazon Web Services (AWS)" },
  { value: "azure", label: "Microsoft Azure" },
  { value: "gcp", label: "Google Cloud Platform (GCP)" },
  { value: "devops", label: "DevOps" },
  { value: "agile", label: "Agile" },
  { value: "uiux", label: "UI/UX Design" },
  { value: "flutter", label: "Flutter" },
];
