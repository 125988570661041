import React, { useEffect, useState } from "react";
import { Title } from "./Title";
import { Filters } from "./Filters";
import { OALoaders } from "../../../Loaders/OALoader/OALoader";
import { JPRecruiterHomeCardview } from "./JPR_HomeCardview";
import { JPRListView } from "./JPR_HomeListView";
import { Pagination, Stack } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecruiterReactQuery } from "../../utils/RecruiterDataContext";
import { RECRUITER_TOKEN_NAME } from "../../common/constant";

export function JPRHome() {
  const navigate = useNavigate();

  const { data, isLoading, error } = useRecruiterReactQuery();

  const { candidates = [], recruiter } = data || {};
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [workStatus, setWorkStatus] = useState("All");
  const [dateStatus, setDateStatus] = useState("new_to_old");
  const [skillsStatus, setSkillsStatus] = useState([]);
  const [designType, setDesignType] = useState(true);
  const [page, setPage] = useState(1);
  const [overAllPages, setOverAllPages] = useState(1);

  useEffect(() => {
    if (recruiter?.interestedIn) {
      // Map the recruiter's interestedIn array to the desired format
      const mappedSkills = recruiter.interestedIn.map((skill) => ({
        value: skill,
        label: skill,
      }));

      // Set the mapped skills to skillsStatus
      setSkillsStatus(mappedSkills);
    }
  }, [recruiter]);

  // useEffect(() => console.log(skillsStatus), [skillsStatus]);

  useEffect(() => {
    setOverAllPages(Math.ceil(filteredCandidates.length / 6));
  }, [filteredCandidates]);

  useEffect(() => {
    const handleScroll = () =>
      window.document
        .querySelector("nav")
        .classList.toggle("active-nav", window.scrollY >= 50);

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [start, setStart] = useState(0);

  useEffect(() => setStart((page - 1) * 6), [page]);
  useEffect(() => setPage(1), [candidates]);

  // last
  // useEffect(() => {
  //   const isAscendingOrder = dateStatus === "old_to_new";

  //   // Sort candidates by createdAt time
  //   const sortedCandidates = [...candidates].sort((a, b) => {
  //     const dateA = new Date(a.createdAt);
  //     const dateB = new Date(b.createdAt);

  //     return isAscendingOrder ? dateA - dateB : dateB - dateA;
  //   });

  //   const filteredCandidates = sortedCandidates.filter((candidate) => {
  //     if (skillsStatus.length) {
  //       return (
  //         skillsStatus.every((skill) =>
  //           candidate.skills.includes(skill.value)
  //         ) &&
  //         (workStatus === "All" || candidate.workStatus === workStatus)
  //       );
  //     } else {
  //       return workStatus === "All" || candidate.workStatus === workStatus;
  //     }
  //   });

  //   setFilteredCandidates(filteredCandidates);
  //   setOverAllPages(Math.ceil(filteredCandidates.length / 6));
  // }, [candidates, skillsStatus, workStatus, dateStatus]);

  useEffect(() => {
    const isAscendingOrder = dateStatus === "old_to_new";

    // Sort candidates by createdAt time
    const sortedCandidates = [...candidates].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      return isAscendingOrder ? dateA - dateB : dateB - dateA;
    });

    const filteredCandidates = sortedCandidates.filter((candidate) => {
      if (skillsStatus.length) {
        return (
          candidate.skills.length > 0 && // Exclude candidates with empty skills array
          skillsStatus.every((skill) =>
            candidate.skills.includes(skill.value)
          ) &&
          (workStatus === "All" || candidate.workStatus === workStatus)
        );
      } else {
        return (
          candidate.skills.length > 0 && // Exclude candidates with empty skills array
          (workStatus === "All" || candidate.workStatus === workStatus)
        );
      }
    });

    setFilteredCandidates(filteredCandidates);
    setOverAllPages(Math.ceil(filteredCandidates.length / 6));
  }, [candidates, skillsStatus, workStatus, dateStatus]);

  if (error) {
    console.error("Error fetching candidate data:", error);

    if (error.response.status === 401) {
      localStorage.removeItem(RECRUITER_TOKEN_NAME);
      navigate("/job-portal/recruiter/login");
    }

    return <p>Error fetching data</p>;
  }

  return (
    <>
      <Title page={page} overAllPages={overAllPages} />
      <Filters
        dateStatus={dateStatus}
        setDateStatus={setDateStatus}
        skillsStatus={skillsStatus}
        setSkillsStatus={setSkillsStatus}
        workStatus={workStatus}
        setWorkStatus={setWorkStatus}
        designType={designType}
        setDesignType={setDesignType}
      />

      {isLoading && (
        <div className="d-flex justify-content-center align-items-center h-50vh">
          <OALoaders />
        </div>
      )}

      {!isLoading &&
        (designType ? (
          <JPRecruiterHomeCardview data={filteredCandidates} start={start} />
        ) : (
          <JPRListView data={filteredCandidates} start={start} />
        ))}

      <Stack spacing={2} className="center my-5">
        <Pagination
          count={overAllPages}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={(e, p) => {
            setPage(p);
            setStart((p - 1) * 6);
          }}
        />
      </Stack>
    </>
  );
}
