import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export function Logout({ state, logoutHandle }) {
  const [isLogout, setLogout] = state;

  const handleClose = () => {
    setLogout(false);
  };

  return (
    <Dialog
      open={isLogout}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: "1.5rem" }}>
        Logout Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ fontSize: "1.5rem" }}
        >
          Are you sure you want to log out?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          sx={{ fontSize: "1.5rem" }}
        >
          Cancel
        </Button>
        <Button
          onClick={logoutHandle}
          color="error"
          autoFocus
          sx={{ fontSize: "1.5rem" }}
        >
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
}
