import React from "react";
import { Outlet } from "react-router-dom";
import { IoIosSettings } from "react-icons/io";

export function JPRSettings() {
  return (
    <main className="settings-form">
      <h4 className="d-flex align-items-center fs-5 mt-0">
        <IoIosSettings />
        Settings
      </h4>
      <hr />
      <Outlet />
    </main>
  );
}
