import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import ReactSelect from "react-select";
import { IT_SKILLS } from "../../common/constant";

export function Filters({
  workStatus,
  setWorkStatus,
  dateStatus,
  setDateStatus,
  skillsStatus,
  setSkillsStatus,
  designType,
  setDesignType,
}) {
  let doc = window.document;

  // console.log(dateStatus, "dateStatus");

  const options = [
    { value: "python", label: "python" },
    { value: "javascript", label: "javascript" },
    { value: "java", label: "java" },
    { value: "ruby", label: "ruby" },
    { value: "flutter", label: "flutter" },
  ];

  const changeView = (designType) => {
    const listView = doc.querySelector(".list-view");
    const cardView = doc.querySelector(".card-view");
    if (designType === "list") {
      listView.id = "active";
      cardView.id = "none";
      setDesignType(false);
    } else {
      listView.id = "none";
      cardView.id = "active";
      setDesignType(true);
    }
  };

  return (
    <header>
      <section className="search-text d-flex">
        <ReactSelect
          className="basic-multi-select fs-4"
          placeholder="Filter by Skills"
          isMulti
          name="colors"
          value={skillsStatus}
          onChange={setSkillsStatus}
          options={IT_SKILLS}
          classNamePrefix="select"
          styles={{
            control: (base) => ({ ...base, width: "100%", minHeight: "60px" }),
          }}
        />
      </section>
      <main className="d-flex">
        <section className="toggle-btn d-flex mr-2">
          <div
            className="list-view center p-1 bg-light"
            id="none"
            onClick={changeView.bind(this, "list")}
          >
            <AiOutlineUnorderedList />
          </div>
          <div
            className="card-view center p-1 bg-light"
            id="active"
            onClick={changeView.bind(this, "grid")}
          >
            <CgMenuGridR />
          </div>
        </section>
        <section className="filter-btn center bg-light fs-10 p-1 pe-auto">
          <BsFilter className="mr-1" />
          <FormControl sx={{ flex: 1 }} size="small">
            <InputLabel id="demo-select-small">Work Status</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={workStatus}
              label="workStatus"
              onChange={(e) => setWorkStatus(e.target.value)}
              sx={{ fontSize: "16px" }}
            >
              <MenuItem sx={{ fontSize: "14px" }} value="All">
                All
              </MenuItem>
              <MenuItem sx={{ fontSize: "14px" }} value="Fresher">
                Fresher
              </MenuItem>
              <MenuItem sx={{ fontSize: "14px" }} value="Experienced">
                Experienced
              </MenuItem>
            </Select>
          </FormControl>
        </section>
        <section className="filter-btn center bg-light p-1 pe-auto">
          <BsFilter className="mr-1" />
          <FormControl sx={{ flex: 1 }} size="small">
            <InputLabel id="Date">Date</InputLabel>
            <Select
              labelId="Date"
              id="Date"
              value={dateStatus}
              label="Date"
              onChange={(e) => setDateStatus(e.target.value)}
              sx={{ fontSize: "16px" }}
            >
              <MenuItem sx={{ fontSize: "12px" }} value="new_to_old">
                Newest First
              </MenuItem>
              <MenuItem sx={{ fontSize: "12px" }} value="old_to_new">
                Oldest First
              </MenuItem>
            </Select>
          </FormControl>
        </section>
      </main>
    </header>
  );
}
