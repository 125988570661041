import "./_jp_welcome.scss";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Rectangles from "../../Image/rectangles.svg";
import CirclePattern from "../../Image/circlepattern.svg";
import AdapptLight from "../../Image/jp_companies/Adappt_light 100 w.png";
import Roadmap from "../../Image/jp_companies/Roadmap_Logo (2).png";
// import AlwNew from "../../Image/jp_companies/alw-new (100w).png";
import BlackPrometheanb from "../../Image/jp_companies/blackprometheanb (100w).png";
import Esales from "../../Image/jp_companies/esales_logo (100 w).png";
import EV from "../../Image/jp_companies/ev_logo.png 100w.png";
import Kosoft from "../../Image/jp_companies/kosoft_new_logo-scaled-180x54 (100w).jpg";
// import Logo from "../../Image/jp_companies/logo (1) (100w).png";
import LogoWhite from "../../Image/jp_companies/logo-white (100w).png";
import Logoef from "../../Image/jp_companies/logo_efee3b140fa7e4e8c84c8c2deded6ded_1x (100 w).png";
import Marquee from "react-fast-marquee";

export function JPW() {
  const navigate = useNavigate();

  const studentFunc = () => {
    const token = localStorage.getItem("candidate_userId");
    if (token) {
      return navigate("/job-portal/candidate/profile");
    }
    navigate("candidate/login");
  };

  const recuritorFunc = () => {
    navigate("recruiter/login");
  };

  return (
    <>
      {/* <AppbarCombo />
      <SideNavbar /> */}
      <main className="job-portal responsive-text container mt-4">
        <img
          src={Rectangles}
          alt="not found"
          style={{ position: "fixed", top: "150px", left: "0px", zIndex: "-1" }}
        />
        <img
          src={CirclePattern}
          alt="not found"
          style={{
            position: "fixed",
            bottom: "0px",
            left: "0px",
            zIndex: "-1",
          }}
        />
        <img
          src={CirclePattern}
          alt="not found"
          style={{
            position: "fixed",
            top: "100px",
            right: "0px",
            zIndex: "-1",
            fill: "red !important",
          }}
        />
        {/* <p className="title text-center my-1 fs-em-17">Welcome Back</p> */}
        <p className="subtitle text-center my-1 fs-em-10">
          Join OA Job Portal Community
        </p>
        <main className="row justify-content-center">
          <section className="card my-3">
            <img
              src="https://elements-cover-images-0.imgix.net/ecb5c878-4588-4604-bcc2-c37a96cb6693?auto=compress%2Cformat&fit=max&w=900&s=07216a61bc1f332327824f734bb5b7b9"
              alt=""
            />
            <p className="text-center fs-em-7 my-4">
              Are you student / Applying Job ?
            </p>

            <button className="btn-student my-3" onClick={studentFunc}>
              Click Here
              <span>
                <MdChevronRight className="icon" />
              </span>
              <span>
                <MdChevronRight className="icon" />
              </span>
              <span>
                <MdChevronRight className="icon" />
              </span>
            </button>
          </section>
          <section className="card my-3">
            <img
              src="https://cdni.iconscout.com/illustration/premium/thumb/welcome-to-team-2931995-2459063.png"
              alt=""
            />
            <p className="text-center fs-em-7 my-4">
              Are you Recuritor / Job Provider ?
            </p>
            <button className="btn-recuritor my-3" onClick={recuritorFunc}>
              Click Here
              <span>
                <MdChevronRight className="icon" />
              </span>
              <span>
                <MdChevronRight className="icon" />
              </span>
              <span>
                <MdChevronRight className="icon" />
              </span>
            </button>
          </section>
        </main>

        <Marquee pauseOnHover speed={50} className="my-5">
          <img
            src={AdapptLight}
            style={{ maxWidth: "100px", marginInline: "30px" }}
            alt="img"
          />
          <img
            src={Roadmap}
            style={{ maxWidth: "100px", marginInline: "30px" }}
            alt="img"
          />
          {/* <img
            src={AlwNew}
            style={{ maxWidth: "100px", marginInline: "30px" }}
            alt="img"
          /> */}
          <img
            src={BlackPrometheanb}
            style={{ maxWidth: "100px", marginInline: "30px" }}
            alt="img"
          />
          <img
            src={Esales}
            style={{ maxWidth: "100px", marginInline: "30px" }}
            alt="img"
          />
          <img
            src={EV}
            style={{ maxWidth: "100px", marginInline: "30px" }}
            alt="img"
          />
          <img
            src={Kosoft}
            style={{ maxWidth: "100px", marginInline: "30px" }}
            alt="img"
          />
          {/* <img
            src={Logo}
            style={{ maxWidth: "100px", marginInline: "30px" }}
            alt="img"
          /> */}
          <img
            src={LogoWhite}
            style={{ maxWidth: "100px", marginInline: "30px" }}
            alt="img"
          />
          <img
            src={Logoef}
            style={{ maxWidth: "100px", marginInline: "30px" }}
            alt="img"
          />
        </Marquee>
      </main>
    </>
  );
}
