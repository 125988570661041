import { useNavigate } from "react-router-dom";
import "./_jpcr.scss";
import Rectangles from "../../../Image/rectangles.svg";
import CirclePattern from "../../../Image/circlepattern.svg";
import { Appbar } from "../../../Appbar/Appbar";
import { useEffect, useState } from "react";
// import { encryptData } from "../../common/encrypt";
import { jobPortal } from "../../../..";
// import { SnackbarComponent } from "../../common/Snackbar";
// import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { useForm } from "react-hook-form";
import { RegisterSchema } from "../Validation/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { SuccessConfirmation } from "../../Components/SuccessConfirmation";
import { AlertTitle, Typography } from "@mui/material";
import { Alert } from "../../JP-Recruiter/JPR-Home/Alert";

export function JPCR() {
  const [loading, setLoading] = useState(false); // New state for loading
  const [isRegisterSuccess, setRegisterSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isopenAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(RegisterSchema) });

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true); // Set loading to true on submission
    // Omit the confirmPassword field from the data
    const { confirmPassword, ...newData } = data;

    try {
      const response = await jobPortal.post(
        "candidate/create-request",
        newData
      );

      console.log(response.data, "--------");
      setRegisterSuccess(true);
      reset({
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
    } catch (error) {
      // Handle error, e.g., display a message to the user or log it
      console.error("Error during registration:", error);
      setErrorMessage(error.response.data.message);
      setOpenAlert(true);
      // reset({
      //   fullName: "",
      //   email: "",
      //   password: "",
      //   confirmPassword: "",
      // });
    } finally {
      setLoading(false); // Set loading to false after submission (success or error)
    }
  });

  useEffect(() => {
    const handlePaste = (e) => {
      e.preventDefault();
    };

    const inputTypesToPreventPaste = ["password"];

    inputTypesToPreventPaste.forEach((inputType) => {
      const inputs = document.querySelectorAll(`input[type="${inputType}"]`);

      if (inputs.length > 0) {
        inputs.forEach((input) => {
          input.addEventListener("paste", handlePaste);
        });

        return () => {
          inputs.forEach((input) => {
            input.removeEventListener("paste", handlePaste);
          });
        };
      }
    });
  }, []);

  // const verify = () => {
  // jobPortal
  //     .post("candidate/send-code", {
  //         email: data.email,
  //         password: encryptData(data.password),
  //     })
  //     .then((response) => {
  //         // clear();
  //         console.log(response.data, "then");
  //         navigate("/job-portal/candidate/verify");
  //     }).catch((e) => {
  //         console.log(e, "e");
  //     });
  // };

  // const handleSignUp = handleSubmit(async (data) => {
  //   try {

  //     const password = encryptData(data.password);
  //     const email = data.email;
  //     const fullName = data.fullName;

  //     const response = await jobPortal.post("candidate/create", {
  //       profile:
  //         "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg",
  //       email: email,
  //       password: password,
  //       fullName: fullName,
  //       googleOuth: false,
  //     });

  //     if (response.status === 200) {
  //       setMessage(response.data.message);
  //     } else if (response.status === 201) {
  //       setMessage(response.data.message);
  //     } else if (response.status === 202) {
  //       setMessage(response.data.message);
  //     }

  //   } catch (error) {
  //     console.error(error.response.data, "---error"); // Log the error response data
  //     setMessage(error.response.data.error);
  //   }
  // })

  const googleOuthSuccess = async (data) => {
    const decodedData = await jwtDecode(data.credential);
    try {
      const response = await jobPortal.post("candidate/create-google-outh", {
        email: decodedData.email,
      });
      console.log(response.data.message, "response");
      setRegisterSuccess(true);
      // navigate("/job-portal/candidate/profile", { state: response.data })

      // console.log(response, "then");
      // setMessage(response.data);
    } catch (error) {
      console.error(error.response.data, "---error"); // Log the error response data
      if (error.response.status === 409) {
        setErrorMessage(error.response.data.error);
        setOpenAlert(true);
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("candidate_userId");
    if (token) {
      return navigate("/job-portal/candidate/profile");
    }
  }, []);

  // useEffect(() => {
  //   if (errorMessage) {
  //     const timeoutId = setTimeout(() => {
  //       setErrorMessage(null);
  //     }, 10000);

  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [errorMessage]);

  // // Function to trigger the error alert
  // const showErrorMessage = (errorMessage) => {
  //   setErrorMessage(errorMessage);
  // };

  return (
    <>
      {/* <SnackbarComponent isBool={message ? true : false} message={message} /> */}
      <Appbar />
      <main className="jobportalRegister container center">
        <img
          src={Rectangles}
          alt="not found"
          style={{ position: "fixed", left: "0px", zIndex: "-1" }}
        />

        <img
          src={CirclePattern}
          alt="not found"
          style={{ position: "fixed", right: "0px", top: "0px", zIndex: "-1" }}
        />
        <section className="register-card flex-col">
          <article className="text-center f-10">
            Create Your Job Seeker Account &#128075;
          </article>

          {/* <article className="mb-2">
            Please fill in the form below to create your account.
          </article> */}

          <article className="text-center mb-2">
            Welcome to our Job Portal!
          </article>

          {/* <GoogleOAuthProvider clientId="253446058996-qus8s0lvjrcohurbhjnnd1s13r8sik12.apps.googleusercontent.com">
            <div className="google-oauth-btn bg-dark center pe-auto">
              <GoogleLogin
                onSuccess={googleOuthSuccess}
                onError={(data) => {
                  console.log("failed", data);
                }}
              />
            </div>
          </GoogleOAuthProvider> */}
          {/* <div className="or row align-items-center my-2">
            <hr />
            <span>Or use email</span>
            <hr />
          </div> */}
          <input
            type="text"
            name="fullName"
            placeholder="Fullname"
            {...register("fullName")}
          />
          <p className="warning f-2 my-1">{errors.fullName?.message}</p>
          <input
            type="email"
            name="email"
            placeholder="Email"
            {...register("email")}
          />
          <p className="warning f-2 my-1">{errors.email?.message}</p>
          <input
            id="password"
            type="password"
            name="password"
            placeholder="Password"
            {...register("password")}
          />
          <p className="warning f-2 my-1">{errors.password?.message}</p>
          <input
            id="password"
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            {...register("confirmPassword")}
          />
          <p className="warning f-2 my-1">{errors.confirmPassword?.message}</p>

          <div
            className={`register-btn center my-2 ${loading ? "disabled" : "diagonal-close"
              }`}
            // Disable the onClick event when loading
            onClick={loading ? null : onSubmit}
          >
            {loading ? <span>Loading...</span> : <span>Register</span>}{" "}
          </div>
          <div className="or m-auto">or</div>
          <div
            className="login-btn diagonal-close center pe-auto my-2"
            onClick={() => navigate("/job-portal/candidate/login")}
          >
            <span>Login</span>
          </div>
          {/* <p className="text-center">{errorMessage}</p> */}

          <Alert state={[isopenAlert, setOpenAlert]}>
            <Typography gutterBottom sx={{ fontSize: "1.8rem" }}>
              {errorMessage}
            </Typography>
          </Alert>

          <SuccessConfirmation state={[isRegisterSuccess, setRegisterSuccess]}>
            <Typography
              color="green"
              gutterBottom
              style={{ fontSize: "2rem", fontWeight: "bolder" }}
            >
              Registration Successful😊!
            </Typography>
            <Typography
              gutterBottom
              style={{ fontSize: "1.5rem", fontWeight: "bold" }}
            >
              A verification link has been sent to your email.
            </Typography>
            <Typography gutterBottom style={{ fontSize: "14px" }}>
              Please check your inbox and follow the instructions to complete
              the registration process.
            </Typography>
            <Typography gutterBottom style={{ fontSize: "14px" }}>
              If you have any concerns or need further assistance, please reach
              out to our Admin.
            </Typography>
          </SuccessConfirmation>
        </section>
      </main>
    </>
  );
}
