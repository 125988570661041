import { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import { RECRUITER_TOKEN_NAME } from "../common/constant";
import { jobPortal } from "../../..";
import { useNavigate } from "react-router-dom";

const RecruiterDataContext = createContext();

export const useRecruiterReactQuery = () => {
  return useContext(RecruiterDataContext);
};

export const RecruiterDataProvider = ({ children }) => {
  const navigate = useNavigate();
  const recruiterReactQuery = useQuery("recruiter", async () => {
    const token = localStorage.getItem(RECRUITER_TOKEN_NAME);
    const response = await jobPortal.get(`recruiter/get-all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });

  //   if (recruiterReactQuery.error) {
  //     console.error("Error fetching candidate data:", recruiterReactQuery.error);

  //     if (recruiterReactQuery.error.response.status === 401) {
  //       localStorage.removeItem(RECRUITER_TOKEN_NAME);
  //       navigate("/job-portal/recruiter/login");
  //     }

  //     return <p>Error fetching data</p>;
  //   }

  return (
    <RecruiterDataContext.Provider value={recruiterReactQuery}>
      {children}
    </RecruiterDataContext.Provider>
  );
};
