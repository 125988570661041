import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useLogout } from "../JP-Recruiter/Context/LogoutAlertContext";
import { RECRUITER_TOKEN_NAME } from "../common/constant";
import { useNavigate } from "react-router-dom";

export function LogoutAlert() {
  const navigate = useNavigate();
  const { logoutAlert, closeLogoutAlert } = useLogout();

  const handleLogout = () => {
    console.log(localStorage.clear(RECRUITER_TOKEN_NAME));
    navigate("/job-portal/recruiter/login");
  };

  return (
    <Dialog
      open={logoutAlert}
      onClose={closeLogoutAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: "1.5rem" }}>
        Logout Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ fontSize: "1.5rem" }}
        >
          Are you sure you want to log out?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeLogoutAlert}
          color="primary"
          sx={{ fontSize: "1.5rem" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleLogout}
          color="error"
          autoFocus
          sx={{ fontSize: "1.5rem" }}
        >
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
}
