import React, { createContext, useContext, useState } from "react";

const LogoutContext = createContext();

export function useLogout() {
  return useContext(LogoutContext);
}

export function LogoutAlertProvider({ children }) {
  const [logoutAlert, setLogoutAlert] = useState(false);

  const openLogoutAlert = () => {
    setLogoutAlert(true);
  };

  const closeLogoutAlert = () => {
    setLogoutAlert(false);
  };

  return (
    <LogoutContext.Provider
      value={{ logoutAlert, openLogoutAlert, closeLogoutAlert }}
    >
      {children}
    </LogoutContext.Provider>
  );
}
