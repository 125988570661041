import React, { useEffect } from 'react';
import "./_jpc_verify.scss";
import { Link, useParams } from 'react-router-dom';
import { jobPortal } from '../../../..';
import { useState } from 'react';
import Lottie from 'react-lottie';
import VERIFIED_GIF from '../../../../lotties/verified.json';
import EXPIRED_GIF from '../../../../lotties/expired.json';

export function JPCVerified() {
    const { token } = useParams();
    const [message, setMessage] = useState();
    const [isVerified, setVerified] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await jobPortal.post(`candidate/verify/${token}`);
                setVerified(true);
                console.log(response.data, "then");
                setMessage(response.data.message);
            } catch (error) {
                console.error(error.response?.data || error.message, "---error");
                setVerified(false);
                setMessage("Expired");
            }
        };

        if (token) {
            fetchData();
        }

    }, [token]);

    const verifiedGif = {
        loop: true,
        autoplay: true,
        animationData: VERIFIED_GIF,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const expiredGif = {
        loop: true,
        autoplay: true,
        animationData: EXPIRED_GIF,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div style={{ width: "90%", marginInline: "auto" }} className="jpc-verified text-center">
            {isVerified ? <Lottie
                options={verifiedGif}
                // height={400}
                width="90%"
                style={{ maxWidth: "400px" }}
            /> : <Lottie
                options={expiredGif}
                // height={400}
                width="90%"
                style={{ maxWidth: "400px" }}
            />}
            <h1 className='f-8'>{message}</h1>
            <Link className='f-4' to="/job-portal/candidate/login">Go to Ocean Academy</Link>
            <p className="copyright f-2 my-3">&copy; Ocean Academy</p>
        </div>
    )
}
