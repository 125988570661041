import React, { useEffect, useState } from "react";
import "./JP_RecruiterStyle.scss";
import Rectangles from "../../../Image/rectangles.svg";
import CirclePattern from "../../../Image/circlepattern.svg";
import { Link, useNavigate } from "react-router-dom";
// import { AppbarCombo } from "../../../AppbarCombo/AppbarCombo";
import { Appbar } from "../../../Appbar/Appbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema } from "../validations";
import { useForm } from "react-hook-form";
import { jobPortal } from "../../../..";
import { RECRUITER_TOKEN_NAME } from "../../common/constant";
import { Alert, AlertTitle } from "@mui/material";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export function JPRecruiterLogin() {
  const navigate = useNavigate();
  // const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({ resolver: yupResolver(LoginSchema) });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await jobPortal.post("recruiter/login", data);
      const token = response.data.token;

      // Store the token in local storage or wherever you manage your authentication state
      localStorage.setItem(RECRUITER_TOKEN_NAME, token);

      reset({
        email: "",
        password: "",
      });

      // Redirect to the desired page after successful login
      navigate("/job-portal/recruiter/dashboard/home");
    } catch (error) {
      // Handle login failure
      console.error(error.response?.data || error.message);
      setErrorMessage(error.response?.data?.error || "Login failed");
    }
  });

  useEffect(() => {
    const token = localStorage.getItem(RECRUITER_TOKEN_NAME);
    if (token) {
      navigate("/job-portal/recruiter/dashboard/home");
    }
  }, [navigate]);

  useEffect(() => {
    // Automatically clear the error message after 3000 milliseconds (3 seconds)
    setTimeout(() => {
      setErrorMessage(null);
    }, 3000);
  }, [errorMessage]);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Appbar />
      <main className="jobportalLogin container center">
        <img
          src={Rectangles}
          alt="not found"
          style={{ position: "fixed", left: "0px", zIndex: "-1" }}
        />

        <img
          src={CirclePattern}
          alt="not found"
          style={{ position: "fixed", right: "0px", top: "0px", zIndex: "-1" }}
        />
        <section className="login-card flex-col">
          <article className="text-center f-10">
            Hello Recruiter &#128075;
          </article>
          <article className="text-center mb-2">
            Welcome back! Please enter your credential
          </article>
          {errorMessage && (
            <Alert
              severity="error"
              style={{ width: "100%", fontSize: "1.2rem" }}
            >
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          )}

          <input type="text" placeholder="UserId" {...register("userId")} />
          <p className="warning f-2 my-1">{errors.userId?.message}</p>

          <div
            className="password-input"
          >
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              {...register("password")}
            />
            {showPassword ? <FaEyeSlash className="eye-toggle" onClick={toggleShowPassword} /> : <FaEye className="eye-toggle" onClick={toggleShowPassword} />}
          </div>
          <p className="warning f-2 my-1">{errors.password?.message}</p>
          {/* <article className="ml-auto my-1">Forget Password?</article> */}
          <div
            className="login-btn diagonal-close center pe-auto my-2"
            onClick={onSubmit}
          >
            <span>Login</span>
          </div>
          <div className="or m-auto">or</div>
          <Link
            to="/job-portal/recruiter/register"
            className="register-btn diagonal-close center pe-auto my-2"
          >
            <span>Register</span>
          </Link>
        </section>
      </main>
    </>
  );
}
