import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ProfileTick from "../../../Image/profile-tick.svg";
import { formatDateString } from "../../common/helperFunctions";

export function JPRecruiterHomeCardview({ data, start }) {
  const navigate = useNavigate();

  const downloadResume = async (resumeLink) => {
    try {
      const response = await fetch(resumeLink);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "resume.pdf";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading resume:", error);
    }
  };

  return (
    <main className="card-wrapper">
      {data.slice(start, start + 6).map((data, index) => (
        <section className="card" key={index}>
          <div className="avatar-circle">
            <img
              className="profile circle"
              src={data.profile}
              alt="Not found"
              width="100%"
            />
            <img className="tick" src={ProfileTick} alt="" />
          </div>
          <article className="f-6 text-center">{data.fullName}</article>
          <article className="f-2 text-center">{data.email}</article>
          <article className="text-center my-1">
            {data.countryCode} {data.phoneNumber}
          </article>
          <article className="text-center">{data.workStatus}</article>
          <Link
            to={`/job-portal/recruiter/dashboard/profile/${data._id}`}
            className="view-btn center text-line-none"
            onClick={downloadResume.bind(this, data.resume)}
          >
            View
          </Link>
          <div className="update-time-btn bg-primary center px-4 py-1">
            {formatDateString(data.createdAt)}
          </div>
        </section>
      ))}
    </main>
  );
}
