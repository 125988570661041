import React, { useEffect } from "react";
import "./_candidate_profileview.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Avatar from "../../images/avatar.svg";
import { jobPortal } from "../../../..";
import { useQuery } from "react-query";
import { OALoaders } from "../../../Loaders/OALoader/OALoader";
import { Logout } from "../../Components/Logout";
import { IoMdArrowBack } from "react-icons/io";

export function CandidateProfileView() {
  const navigate = useNavigate();
  const [isLogout, setLogout] = useState(false);

  // Get the id parameter from the URL
  const { id } = useParams();

  const { data, error, isLoading } = useQuery(
    ["recruiter/get-candidate", { id }],
    async () => {
      try {
        const token = localStorage.getItem("__r__userId");
        const response = await jobPortal.post(
          "recruiter/get-candidate",
          { id }, // Include id in the request body
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response.data, "got-response"); // Log the data to the console

        return response.data;
      } catch (error) {
        console.error("Error fetching candidate data:", error);
        throw error; // Rethrow the error to be caught in the error handling block
      }
    }
  );

  // console.log(data, "got");

  // useEffect(() => {
  //   if (data && data.profile === null && data.resume === null) {
  //     navigate("/job-portal/candidate/data-collect");
  //   }
  //   console.log(data);
  // }, [data, navigate]);

  if (isLoading) {
    return (
      <main className="d-flex justify-content-center align-items-center h-100vh">
        <OALoaders />
      </main>
    );
  }

  if (error) {
    console.error("Error fetching candidate data:", error);

    if (error.response && error.response.status === 401) {
      localStorage.removeItem("candidate_userId");
      navigate("/job-portal/candidate/login");
    }

    return <p>Error fetching data</p>;
  }

  const handleViewResume = () => {
    // Check if resume is available
    if (data && data.resume) {
      // Open the resume in a new tab
      window.open(data.resume, "_blank");
    } else {
      // Handle the case where resumeURL is not available
      console.error("Resume URL is not available.");
    }
  };

  const logoutHandle = () => {
    localStorage.clear("candidate_userId");
    setLogout(false);
    // Clear the entire navigation history
    navigate("/job-portal/candidate/login", {
      replace: true,
      state: { resetHistory: true },
    });
  };

  function formatDateString(dateString) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  }

  return (
    <>
      {/* <Appbar /> */}
      <section className="jp-recruiter-candidateView pb-5">
        <article
          className="d-flex align-items-center mb-2 pe-auto"
          onClick={() => navigate(-1)}
        >
          <IoMdArrowBack />
          <p className="mx-1">Go Back</p>
        </article>

        <img className="bg-view" alt="" />

        <main className="info d-flex justify-content-between align-items-center">
          <article className="profile d-flex align-items-center">
            <div className="image circle-avatar">
              <img
                src={data.profile ? data.profile : Avatar}
                alt="img"
                width="100%"
              />
            </div>
            <p className="f-6 ml-1 mt-4">{data.fullName}</p>
          </article>
          <article className="d-flex group-btns">
            <div
              className="view-resume-btn pe-auto mr-1"
              onClick={handleViewResume}
              download
            >
              Download Resume
            </div>
            {/* <a
              className="view-resume-btn pe-auto mr-1"
              href={data.resume}
              download
            >
              download
            </a> */}
            <a
              href={`tel:${data.countryCode + data.phoneNumber}`}
              className="call-btn pe-auto text-line-none"
            >
              Call
            </a>
          </article>
        </main>

        <main className="profile d-flex g-15">
          <section style={{ flexGrow: 2 }}>
            <section
              className="bg-light mb-3"
              style={{
                borderRadius: "10px",
                gap: "10px 10px",
              }}
            >
              <article className="personal-info py-1">
                <p className="f-7">All Personal Information</p>
                <section className="row justify-content-between g-6">
                  <article>
                    <p className="f-4 fw-500 text-primary">Gender</p>
                    <p className="f-3 text-secondary">{data.gender}</p>
                  </article>
                  <article>
                    <p className="f-4 fw-500 text-primary">Phone Number</p>
                    <p className="f-3 text-secondary">
                      {data.countryCode} {data.phoneNumber}
                    </p>
                  </article>
                  <article>
                    <p className="f-4 fw-500 text-primary">Current City</p>
                    <p className="f-3 text-secondary">{data?.address?.city}</p>
                  </article>
                  <article>
                    <p className="f-4 fw-500 text-primary">Mail Id</p>
                    <p className="f-3 text-secondary">{data.email}</p>
                  </article>
                </section>
                <hr />
                <section className="personal-info-col2 row justify-content-between g-6">
                  <article style={{ width: "100%", flexBasis: "300px" }}>
                    <p className="f-4 fw-500 text-primary">Address</p>
                    <p className="f-3 text-secondary">
                      {data?.address?.street}, {data?.address?.area},{" "}
                      {data?.address?.city} {data?.address?.pincode}
                    </p>
                  </article>

                  <article style={{ width: "100%", flexBasis: "320px" }}>
                    <p className="f-4 fw-500 text-primary">Languages</p>
                    <div className="row">
                      {Array.isArray(data.skills) ? (
                        data.speakingLanguages.map((skill, i) => (
                          <div key={i} className="skill p-1">
                            {skill}
                          </div>
                        ))
                      ) : (
                        <p className="skill p-1 f-3 text-secondary">
                          No Skills found
                        </p>
                      )}
                    </div>
                  </article>
                </section>
                <hr />
                <section className="row justify-content-between">
                  <article style={{ width: "100%", flexBasis: "320px" }}>
                    <p className="f-4 fw-500 text-primary">Date of Birth</p>
                    <p className="f-3 text-secondary">
                      {formatDateString(data.dob)}
                    </p>
                  </article>

                  <article style={{ width: "100%", flexBasis: "320px" }}>
                    <p className="f-4 fw-500 text-primary">Linked In</p>
                    {!data.linkedIn ? (
                      <p className="f-3 text-secondary">Not Given</p>
                    ) : (
                      <a
                        // target="_blank"
                        // href={data.linkedIn}
                        className="f-3 text-secondary pe-auto"
                      >
                        {data.linkedIn}
                      </a>
                    )}
                  </article>
                </section>
              </article>
            </section>

            <section className="bg-light mb-3" style={{ borderRadius: "10px" }}>
              <article className="career-pref py-1">
                <p className="f-7">Your Career Preferences</p>
                <section className="row justify-content-between">
                  <article>
                    <p className="f-4 fw-500 text-primary">Resume</p>
                    <p className="f-3 text-secondary">{data.workStatus}</p>
                  </article>
                  <article>
                    <p className="f-4 fw-500 text-primary">Notice Period</p>
                    <p className="f-3 text-secondary">{data.noticePeriod}</p>
                  </article>
                  <article>
                    <p className="f-4 fw-500 text-primary">
                      Salary Expectation
                    </p>
                    <p className="f-3 text-secondary">
                      {data.salaryExpectation} LPA
                    </p>
                  </article>
                </section>
              </article>
            </section>

            {data.projects.length > 0 && (
              <section className="bg-light" style={{ borderRadius: "10px" }}>
                <article className="projects-details py-1">
                  <p className="f-7">Your Projects Details</p>
                  {data.projects.map((project, index) => {
                    // { console.log(project, "project") }
                    return (
                      <main key={project.id}>
                        <article>
                          <p className="f-4 fw-500 text-primary">
                            Project Name
                          </p>
                          <p className="f-3 text-secondary">
                            {project.projectName}
                          </p>
                        </article>
                        <article>
                          <p className="f-4 fw-500 text-primary">
                            Project Description
                          </p>
                          <p
                            className="f-3 text-secondary"
                            style={{
                              width: "100%",
                              maxWidth: "800px",
                              lineHeight: "23px",
                            }}
                          >
                            {project.projectDescription}
                          </p>
                        </article>
                        <article>
                          <p className="f-4 fw-500 text-primary">
                            Project Timeline
                          </p>
                          <p className="f-3 text-secondary">
                            {project.projectStartMonth}{" "}
                            {project.projectStartYear} -{" "}
                            {project.projectEndMonth} {project.projectEndYear}
                          </p>
                        </article>
                        {index < data.projects.length - 1 && (
                          <hr className="my-2" />
                        )}
                      </main>
                    );
                  })}
                </article>
              </section>
            )}
          </section>
          <section
            className="bg-light"
            style={{
              padding: "10px",
              borderRadius: "10px",
              flexGrow: 1,
              flexBasis: "450px",
            }}
          >
            <section className="profile-about">
              <article className="col">
                <p className="f-7 my-1">About</p>

                <p className="f-4 fw-500 text-primary my-1">Profile Summery</p>
                <p className="f-3 text-secondary" style={{ maxWidth: "600px" }}>
                  {data.profileSummary}
                </p>

                <article>
                  <p className="f-4 fw-500 text-primary">Work Status</p>
                  <p className="f-3 text-secondary">{data.workStatus}</p>
                </article>
                {/* <hr /> */}
                <p className="f-4 fw-500 text-primary">Skills</p>
                <div className="row">
                  {Array.isArray(data.skills) ? (
                    data.skills.map((skill, i) => (
                      <div key={i} className="skill p-1">
                        {skill}
                      </div>
                    ))
                  ) : (
                    <div className="skill p-1">No Skills found</div>
                  )}
                </div>
                {/* <hr className="my-2" /> */}

                {data.educations?.length > 0 && (
                  <p className="f-4 fw-500 text-primary">Educations</p>
                )}

                {data.educations.map((education, index) => (
                  <main>
                    <section className="row justify-content-between g-3">
                      <article>
                        <p className="f-4 fw-500 text-primary">Qualification</p>
                        <p className="f-3 text-secondary">
                          {education?.qualification}
                        </p>
                      </article>
                      <article style={{ width: "100%", flexBasis: "160px" }}>
                        <p className="f-4 fw-500 text-primary">
                          Organization Name
                        </p>
                        <p className="f-3 text-secondary">
                          {education?.organizationName}
                        </p>
                      </article>
                    </section>
                    <section className="row justify-content-between g-3">
                      <article>
                        <p className="f-4 fw-500 text-primary">Duration</p>
                        <p className="f-3 text-secondary">
                          {education.courseStartYear} -{" "}
                          {education.courseEndYear}
                        </p>
                      </article>
                      <article style={{ width: "100%", flexBasis: "160px" }}>
                        <p className="f-4 fw-500 text-primary">Course Type</p>
                        <p className="f-3 text-secondary">
                          {education?.courseType}
                        </p>
                      </article>
                    </section>
                    {index < data.educations.length - 1 && (
                      <hr className="my-2" />
                    )}
                  </main>
                ))}
                <hr />
              </article>
            </section>
          </section>
        </main>

        <footer className="clr-light py-1 fs-3">
          @copyright oceanacademy 2024
        </footer>
      </section>
      <Logout state={[isLogout, setLogout]} logoutHandle={logoutHandle} />
    </>
  );
}
