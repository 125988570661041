import { Link, useNavigate } from "react-router-dom";
import "./_jpcl.scss";
import Rectangles from "../../../Image/rectangles.svg";
import CirclePattern from "../../../Image/circlepattern.svg";
import { Appbar } from "../../../Appbar/Appbar";
// import { useEffect } from "react";
import { jobPortal } from "../../../..";
import { useEffect, useState } from "react";
// import { encryptData } from "../../common/encrypt";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema } from "../Validation/validation";
import { Alert, AlertTitle } from "@mui/material";
import { CANDIDATE_TOKEN_NAME } from "../../common/constant";
import { useQuery } from "react-query";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// JOB PORTAL CANDIDATE LOGIN
export function JPCL() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false); // New state for loading
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({ resolver: yupResolver(LoginSchema) });

  const onSubmit = handleSubmit(async (data) => {
    console.log(data, "data----");
    try {
      setLoading(true); // Set loading to true on submission
      const response = await jobPortal.post("candidate/login", data);
      const token = response.data.token;

      console.log(response, "response");

      setLoading(false); // Set loading to false after submission

      localStorage.setItem("candidate_userId", token);
      console.log(response.data, "data received");

      const { resume, phoneNumber } = response.data;

      // // Reset the form
      reset({
        email: "",
        password: "",
      });

      // Handle success, e.g., redirect to another page
      if (resume && phoneNumber) {
        return navigate(`/job-portal/candidate/profile`);
      } else if (token) {
        return navigate(`/job-portal/candidate/data-collect`);
      }
    } catch (error) {
      console.error("API request failed:", error.response);
      // Log the entire error object
      console.log(error);
      setErrorMessage(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after submission (success or error)
    }
  });

  useEffect(() => {
    // Automatically clear the error message after 3000 milliseconds (5 seconds)
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  }, [errorMessage]);

  // const { data, error, isLoading } = useQuery(
  //   "candidate",
  //   async () => {
  //     try {
  //       const token = localStorage.getItem(CANDIDATE_TOKEN_NAME);
  //       const response = await jobPortal.get(`candidate/get`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       console.log(response.data); // Log the data to the console

  //       return response.data;
  //     } catch (error) {
  //       console.error("Error fetching candidate data:", error);
  //       throw error; // Rethrow the error to be caught in the error handling block
  //     }
  //   }
  //   // { staleTime: 5 * 60 * 1000 } // 5 minutes in milliseconds
  // );

  useEffect(() => {
    const token = localStorage.getItem(CANDIDATE_TOKEN_NAME);
    if (token) {
      navigate("/job-portal/candidate/profile");
    }
  }, [navigate]);

  //   useEffect(() => {
  //     const token = localStorage.getItem("candidate_userId");

  //     if (token) {
  //       navigate("/job-portal/candidate/profile")
  //     }

  // //     const fetchData = async () => {
  // //       try {
  // //         const response = await jobPortal.post('candidate/get', data);
  // //         const token = response.data.token;

  // //         setLoading(false); // Set loading to false after submission

  // //         localStorage.setItem("candidate_userId", token);
  // //         console.log(response.data, 'data received');

  // //         const { _id, resume, phoneNumber } = response.data;

  // //         // Reset the form
  // //         reset({
  // //           email: '',
  // //           password: '',
  // //         });
  // //         // Handle success, e.g., redirect to another page
  // //         if (resume && phoneNumber) {
  // //           navigate(`/job-portal/candidate/profile`);
  // //         } else {
  // //           navigate(`/job-portal/candidate/data-collect`);
  // //         }

  // //         // navigate()
  // //       } catch (error) {
  // //         console.error('API request failed:', error.response);
  // //       }
  // // }
  //   }, [])

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Appbar />
      <main className="jobportalLogin container center">
        <img
          src={Rectangles}
          alt="not found"
          style={{ position: "fixed", left: "0px", zIndex: "-1" }}
        />

        <img
          src={CirclePattern}
          alt="not found"
          style={{ position: "fixed", right: "0px", top: "0px", zIndex: "-1" }}
        />
        <section className="login-card flex-col">
          <article className="text-center f-10">
            Hello Candidate &#128075;
          </article>
          <article className="mb-2 text-center">
            Welcome back, you've been missed
          </article>
          {/* <div className="google-oauth-btn bg-dark center pe-auto">
            Continue with Google
          </div>
          <div className="or row align-items-center my-2">
            <hr />
            <span>Or use email</span>
            <hr />
          </div> */}
          {errorMessage && (
            <Alert
              severity="error"
              style={{ width: "100%", fontSize: "1.2rem" }}
            >
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          )}
          <input
            type="email"
            name="email"
            placeholder="Email"
            {...register("email")}
          />
          <p className="warning f-2 my-1">{errors.email?.message}</p>
          <div
            className="password-input"
          >
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              {...register("password")}
            />
            {showPassword ? <FaEyeSlash className="eye-toggle" onClick={toggleShowPassword} /> : <FaEye className="eye-toggle" onClick={toggleShowPassword} />}
          </div>
          <p className="warning f-2 my-1">{errors.password?.message}</p>
          <Link
            to="/job-portal/candidate/forget-password"
            className="ml-auto my-1 pe-auto"
          >
            Forget Password?
          </Link>
          <div
            className={`login-btn center my-2 ${loading ? "disabled" : "diagonal-close"
              }`}
            // Disable the onClick event when loading
            onClick={loading ? null : onSubmit}
          >
            {loading ? <span>Loading...</span> : <span>Login</span>}
          </div>
          <div className="or m-auto">or</div>
          <div
            className="register-btn diagonal-close center pe-auto my-2"
            onClick={() => navigate("/job-portal/candidate/register")}
          >
            <span>Register</span>
          </div>
        </section>
      </main>
    </>
  );
}
