import React from "react";
import { formatDateString } from "../../common/helperFunctions";

export function JPRListView({ data, start }) {
  // console.log("JPRListView rendered");
  return (
    <main className="list-wrapper">
      <header>
        <p>Candidate</p>
        <p>Phone Number</p>
        <p>Work Status</p>
        <p>Created Date</p>
        <p>Resume</p>
      </header>

      {data.slice(start, start + 6).map((data, index) => (
        <article
          className="d-flex justify-content-between align-items-center"
          key={index}
        >
          <p className="d-flex justify-conter-center align-items-center">
            <img className="profile" src={data.profile} alt="?"></img>
            {data.fullName}
          </p>
          <p>
            {data.countryCode} {data.phoneNumber}
          </p>
          <p>{data.workStatus}</p>
          <p>{formatDateString(data.createdAt)}</p>
          <p>Download</p>
        </article>
      ))}
    </main>
  );
}
