import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecruiterReactQuery } from "../../utils/RecruiterDataContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { SettingsPasswordUpdate } from "../validations";
import { useNavigate } from "react-router-dom";
import { TfiControlBackward } from "react-icons/tfi";
import { RECRUITER_TOKEN_NAME } from "../../common/constant";
import { jobPortal } from "../../../..";
import { useMutation, useQueryClient } from "react-query";
import { SuccessConfirmation } from "../../Components/SuccessConfirmation";

export function JPRUpdatePassword() {
  const [isPasswordSuccess, setPasswordSuccess] = useState(false);
  const [isPasswordAlert, setPasswordAlert] = useState(null);
  const navigate = useNavigate();
  const { data, isLoading, error } = useRecruiterReactQuery();
  const { recruiter = {} } = data || {};

  const updatePasswordMutation = useMutation(
    (data) => {
      const { oldPassword, newPassword } = data;
      const token = localStorage.getItem(RECRUITER_TOKEN_NAME);

      return jobPortal.put(
        `recruiter/update-password`,
        { oldPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        console.log("Password updated successfully");
        reset({ confirmPassword: "", newPassword: "", oldPassword: "" })
        // Optional: You can perform any actions after the mutation is successful
        setPasswordSuccess(true);
      },
      onError: (error) => {
        console.error("Error updating password:", error.response);
        // Handle error if needed
        // console.log(error.response.data.message);
        showErrorAlert(error.response.data.message);
      },
    }
  );

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(SettingsPasswordUpdate),
  });

  const handleChangePassword = handleSubmit((data) => {
    const { oldPassword, newPassword } = data;

    const newData = {
      oldPassword,
      newPassword,
    };

    // Call the mutation function
    updatePasswordMutation.mutate(newData);
  });

  useEffect(() => {
    if (isPasswordAlert) {
      const timeoutId = setTimeout(() => {
        setPasswordAlert(null);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [isPasswordAlert]);

  // Function to trigger the error alert
  const showErrorAlert = (errorMessage) => {
    setPasswordAlert(errorMessage);
  };

  return (
    <main className="update-password-forms">
      <p
        className="go-back d-inline-flex align-items-center fs-6 my-2 pe-auto"
        onClick={() => navigate(-1)}
      >
        <TfiControlBackward />
        Go Back
      </p>
      {isPasswordAlert && (
        <Alert severity="error" className="my-2">
          <AlertTitle>Error</AlertTitle>

          <Typography gutterBottom sx={{ fontSize: "1.5rem" }}>
            {isPasswordAlert}
          </Typography>
        </Alert>
      )}

      <section className="row col-700">
        <div className="col m-auto">
          <label className="fs-em-5" htmlFor="oldPassword">
            Old Password <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="password"
            name="oldPassword"
            id="oldPassword"
            {...register("oldPassword")}
          />
          <p
            style={{
              color: "red",
              fontSize: "15px",
              paddingBottom: "10px",
            }}
          >
            {errors.oldPassword?.message}
          </p>
          <label className="fs-em-5" htmlFor="newPassword">
            New Password <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="password"
            name="newPassword"
            id="newPassword"
            {...register("newPassword")}
          />
          <p
            style={{
              color: "red",
              fontSize: "15px",
              paddingBottom: "10px",
            }}
          >
            {errors.newPassword?.message}
          </p>
          <label className="fs-em-5" htmlFor="confirmPassword">
            Confirm New Password <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            {...register("confirmPassword")}
          />
          <p
            style={{
              color: "red",
              fontSize: "15px",
              paddingBottom: "10px",
            }}
          >
            {errors.confirmPassword?.message}
          </p>
        </div>
      </section>
      <Button
        style={{ fontSize: "1.4rem", marginBlock: "1rem" }}
        variant="contained"
        color="primary"
        onClick={handleChangePassword}
      >
        Change Password
      </Button>

      <SuccessConfirmation state={[isPasswordSuccess, setPasswordSuccess]}>
        <Typography color="green" gutterBottom style={{ fontSize: "18px" }}>
          Your password has been successfully updated
        </Typography>
        <Typography gutterBottom style={{ fontSize: "14px" }}>
          Thank you for ensuring the security of your account! Please Remember
          to keep your password secure and avoid sharing it with anyone.
        </Typography>
        <Typography gutterBottom style={{ fontSize: "14px" }}>
          If you have any concerns or need further assistance, please reach out
          our Admin😊.
        </Typography>
      </SuccessConfirmation>
    </main>
  );
}
