import { Link, useNavigate } from "react-router-dom";
import "./_candidate_forgetpassword.scss";
import Rectangles from "../../../Image/rectangles.svg";
import CirclePattern from "../../../Image/circlepattern.svg";
import { Appbar } from "../../../Appbar/Appbar";
// import { useEffect } from "react";
import { jobPortal } from "../../../..";
import { useEffect, useState } from "react";
// import { encryptData } from "../../common/encrypt";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  EmailResetPasswordLinkScheme,
  LoginSchema,
} from "../Validation/validation";
import { Alert, AlertTitle, Typography } from "@mui/material";
import { CANDIDATE_TOKEN_NAME } from "../../common/constant";
import { useQuery } from "react-query";
import { MdArrowBack } from "react-icons/md";
import { SuccessConfirmation } from "../../Components/SuccessConfirmation";

// JOB PORTAL CANDIDATE Candidate Forget Password
export function CandidateForgetPassword() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEmailSentSuccess, setEmailSentSuccess] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({ resolver: yupResolver(EmailResetPasswordLinkScheme) });

  const onSubmit = handleSubmit(async (data) => {
    console.log(data, "data----");
    try {
      setLoading(true); // Set loading to true on submission
      const response = await jobPortal.post("candidate/forgot-password", data);

      console.log(response, "response");

      setLoading(false); // Set loading to false after submission
      setEmailSentSuccess(true);

      console.log(response.data, "data received");

      // // Reset the form
      reset({
        email: "",
      });
    } catch (error) {
      console.error("API request failed:", error.response);
      // Log the entire error object
      console.log(error);
      setErrorMessage(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after submission (success or error)
    }
  });

  useEffect(() => {
    // Automatically clear the error message after 3000 milliseconds (5 seconds)
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  }, [errorMessage]);

  useEffect(() => {
    const token = localStorage.getItem(CANDIDATE_TOKEN_NAME);
    if (token) {
      navigate("/job-portal/candidate/profile");
    }
  }, [navigate]);

  return (
    <>
      <Appbar />
      <main className="candidate-forgetpassword container center">
        <img
          src={Rectangles}
          alt="not found"
          style={{ position: "fixed", left: "0px", zIndex: "-1" }}
        />

        <img
          src={CirclePattern}
          alt="not found"
          style={{ position: "fixed", right: "0px", top: "0px", zIndex: "-1" }}
        />
        <section className="login-card flex-col">
          <article className="text-center f-9">
            Did you forgot your Password?
          </article>
          <article className="my-1 text-center f-3">
            Enter your Email Address below and we will send you a password reset
            link to your email address.
          </article>

          {errorMessage && (
            <Alert
              severity="error"
              style={{ width: "100%", fontSize: "1.2rem" }}
            >
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          )}
          <input
            type="email"
            name="email"
            placeholder="Email"
            {...register("email")}
          />
          <p className="warning f-2 my-1">{errors.email?.message}</p>
          <div
            className={`login-btn center my-2 ${loading ? "disabled" : "diagonal-close"
              }`}
            // Disable the onClick event when loading
            onClick={loading ? null : onSubmit}
          >
            {loading ? (
              <span>Loading...</span>
            ) : (
              <span>Request Reset Link</span>
            )}
          </div>
          <div className="or m-auto">or</div>
          <p
            className="d-flex align-items-center m-auto text-center pe-auto"
            onClick={() => navigate(-1)}
          >
            <MdArrowBack className="mr-1" />
            Back to Login In
          </p>
        </section>
      </main>
      <SuccessConfirmation
        state={[isEmailSentSuccess, setEmailSentSuccess]}
        navigateScreen={() => navigate("/job-portal/candidate/login")}
      >
        <Typography
          color="green"
          gutterBottom
          style={{ fontSize: "2rem", fontWeight: "bolder" }}
        >
          Email Sent for Password Reset 📧
        </Typography>
        <Typography
          gutterBottom
          style={{ fontSize: "1.5rem", fontWeight: "bold" }}
        >
          We've sent you an email with instructions to reset your password.
        </Typography>
        <Typography gutterBottom style={{ fontSize: "14px" }}>
          Please check your inbox and follow the provided link to set a new password.
        </Typography>
        <Typography gutterBottom style={{ fontSize: "14px" }}>
          If you don't see the email in your inbox, please check your spam folder. If you encounter any issues, feel free to contact our support team.
        </Typography>
      </SuccessConfirmation>

    </>
  );
}
