import "./_jpr_settings.scss";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { TfiControlBackward } from "react-icons/tfi";
import { useRecruiterReactQuery } from "../../utils/RecruiterDataContext";
import { useEffect, useState } from "react";
import { SettingsProfileUpdate } from "../validations";
import { isEqual } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { jobPortal } from "../../../..";
import { RECRUITER_TOKEN_NAME } from "../../common/constant";
import { SuccessConfirmation } from "../../Components/SuccessConfirmation";
import { Alert } from "./Alert";
import { Typography } from "@mui/material";

export function JPRUpdateProfile() {
  const navigate = useNavigate();
  const [candidateSkills, setCandidateSkills] = useState([]);
  const [isprofileUpdateSuccess, setProfileUpdateSuccess] = useState(false);
  const [isprofileAlert, setProfileAlert] = useState(false);
  const { data, isLoading, error } = useRecruiterReactQuery();
  const queryClient = useQueryClient();

  const { recruiter = {} } = data || {};

  const { fullName, role, phoneNumber, interestedIn, countryCode } = recruiter;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(SettingsProfileUpdate),
    defaultValues: {
      fullName: fullName || "",
      role: role || "",
      interestedIn: interestedIn || [],
      phoneNumber:
        countryCode && phoneNumber ? `${countryCode}${phoneNumber}` : "",
    },
  });

  // Watch for changes in the phoneNumber field
  const watchPhoneNumber = watch("phoneNumber");

  const options = [
    { value: "python", label: "python" },
    { value: "javascript", label: "javascript" },
    { value: "java", label: "java" },
    { value: "ruby", label: "ruby" },
    { value: "flutter", label: "flutter" },
  ];

  // Handle the onChange event of the PhoneInput component
  const handlePhoneChange = (value, data) => {
    const stringValue = String(value); // Ensure value is a string
    setValue("phoneNumber", stringValue);
    setValue("countryCode", `+${data?.dialCode}` || "");
  };

  const mutation = useMutation(
    async (newRecruiter) => {
      const token = localStorage.getItem(RECRUITER_TOKEN_NAME);
      const response = await jobPortal.put(`recruiter/update`, newRecruiter, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        // Refetch the recruiter data after successful update
        queryClient.invalidateQueries("recruiter");
        setProfileUpdateSuccess(true);
      },
    }
  );

  const onSubmit = handleSubmit(async (data) => {
    const phoneNum = `+${data.phoneNumber}`;
    const dialCodeToRemove = data.countryCode;

    const phoneNumberWithoutCode = phoneNum.replace(dialCodeToRemove, "");

    const oldRecruiter = {
      countryCode,
      fullName,
      interestedIn,
      phoneNumber,
      role,
    };

    const newRecruiter = {
      ...data,
      phoneNumber: phoneNumberWithoutCode,
    };

    console.log(newRecruiter, "newRecruiter");
    console.log(oldRecruiter, "oldRecruiter");
    // console.log(phoneNumber, "phoneNumber");
    // console.log(dialCodeToRemove, "dialCodeToRemove");
    console.log(phoneNumberWithoutCode, "phoneNumberWithoutCode");
    console.log(isEqual(oldRecruiter, newRecruiter), "recruiter");
    if (!isEqual(newRecruiter, oldRecruiter)) {
      mutation.mutate(newRecruiter);
    } else {
      setProfileAlert(true);
      console.log("no data changes you can't update");
    }
  });

  useEffect(() => {
    setValue("fullName", fullName);
    setValue("role", role);
    setValue("phoneNumber", parseInt(countryCode) + String(phoneNumber));
    setValue("interestedIn", interestedIn);
    setValue("countryCode", countryCode);

    console.log(interestedIn, "interestedIn");
    console.log(phoneNumber, "phoneNumber");

    if (interestedIn) {
      const defaultValues = options
        .filter((option) => interestedIn.includes(option.value))
        .map((option) => ({ value: option.value, label: option.label }));

      console.log(defaultValues, "defaultValues");

      setCandidateSkills(defaultValues);
    }
  }, [fullName, role, interestedIn, countryCode, phoneNumber]);

  return (
    <main className="profile-forms col g-2">
      <p
        className="go-back d-inline-flex align-items-center fs-6 pe-auto"
        onClick={() => navigate(-1)}
      >
        <TfiControlBackward />
        Go Back
      </p>
      <section className="row col-700 gap-2">
        {/* <div className="col m-auto">
          <label className="fs-em-4" htmlFor="fullName">
            Full Name <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            {...register("fullName")}
          />
          <p
            style={{
              color: "red",
              fontSize: "15px",
              paddingBottom: "10px",
            }}
          >
            {errors.fullName?.message}
          </p>
        </div> */}
        <div className="col m-auto">
          <label className="fs-em-4" htmlFor="role">
            Role <span style={{ color: "red" }}>*</span>
          </label>
          <input type="text" name="role" id="role" {...register("role")} />
          <p
            style={{
              color: "red",
              fontSize: "15px",
              paddingBottom: "10px",
            }}
          >
            {errors.role?.message}
          </p>
        </div>
      </section>
      <div className="col">
        <label className="fs-em-4" htmlFor="phoneNumber">
          Phone Number(Whats App) <span style={{ color: "red" }}>*</span>
        </label>

        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <PhoneInput
              style={{ width: "100%" }}
              inputProps={{
                name: "phoneNumber",
                required: true,
                autoFocus: true,
              }}
              country={"in"}
              placeholder="Enter phone number"
              value={watchPhoneNumber}
              onChange={(value, data) => {
                console.log(value, "value");
                field.onChange(value);
                handlePhoneChange(value, data);
              }}
            />
          )}
        />
        <p className="text-red fs-3">{errors.phoneNumber?.message}</p>
      </div>
      <div className="col mt-4 mb-1">
        <label className="fs-em-5" htmlFor="interestedIn">
          Interested In
          <span style={{ color: "red" }}> *</span>
        </label>

        <Select
          name="interestedIn"
          className="basic-multi-select fs-5 my-2"
          isMulti
          value={candidateSkills}
          onChange={(selectedOptions) => {
            console.log(selectedOptions);
            setCandidateSkills(selectedOptions);
            setValue(
              "interestedIn",
              selectedOptions.map((option) => option.value)
            );
            // field.onChange(selectedOptions.map((option) => option.value));
          }}
          options={options}
          classNamePrefix="select"
        />

        <ErrorMessage
          errors={errors}
          name="interestedIn"
          render={() => (
            <p
              style={{
                color: "red",
                fontSize: "15px",
                paddingBottom: "10px",
              }}
            >
              Skills is required
            </p>
          )}
        />
      </div>
      <div className="profile-update-btn f-5 center my-1" onClick={onSubmit}>
        Update Profile
      </div>

      <SuccessConfirmation
        state={[isprofileUpdateSuccess, setProfileUpdateSuccess]}
      >
        <Typography gutterBottom style={{ fontSize: "16px" }}>
          Your profile has been successfully updated. Thank you for keeping your
          information current! If you have any further changes or updates, feel
          free to make them at any time.
        </Typography>
        <Typography gutterBottom style={{ fontSize: "16px" }}>
          Your dedication to maintaining an accurate profile is crucial for
          connecting with the right candidates.
        </Typography>
        <Typography gutterBottom style={{ fontSize: "16px" }}>
          Happy recruiting ! 😊
        </Typography>
      </SuccessConfirmation>

      <Alert state={[isprofileAlert, setProfileAlert]}>
        <Typography gutterBottom sx={{ fontSize: "1.8rem" }}>
          Your profile is already up to date. No changes were made
        </Typography>
      </Alert>
    </main>
  );
}
