import { Navigate, useParams } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import "./_jpcr_datacollect.scss";
import { TiTick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { jobPortal } from "../../../../index";
import { v4 as uuidv4 } from 'uuid';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Appbar } from "../../../Appbar/Appbar";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DataCollectSchema } from "../Validation/validation";
import { ErrorMessage } from "@hookform/error-message";
import PhoneInput from "react-phone-input-2";
import Avatar from "../../images/avatar.svg";
import { useEffect, useRef, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField, Typography } from "@mui/material";
import { SuccessConfirmation } from "../../Components/SuccessConfirmation";
import Marquee from "react-fast-marquee";
import { IT_SKILLS, courseTypesArray, monthsArray, speakingLanguages, yearsArray } from "../../common/constant";
import { AiOutlineCloseSquare } from "react-icons/ai";

export function JPCRDataCollect() {
  const [projects, setProjects] = useState([]);
  const [educations, setEducation] = useState([]);
  const [isRegisterSuccess, setRegisterSuccess] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading
  // Ref for the file input element
  const profileUploadInputRef = useRef(null);
  // Get the id parameter from the URL
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(DataCollectSchema),
    defaultValues: {
      city: DataCollectSchema.fields.city.default(),
      dob: "",
      projects: DataCollectSchema.fields.projects.default(),
      educations: DataCollectSchema.fields.educations.default(),
    },
  });

  // const options = [
  //   { value: "python", label: "python" },
  //   { value: "javascript", label: "javascript" },
  //   { value: "java", label: "java" },
  //   { value: "ruby", label: "ruby" },
  //   { value: "flutter", label: "flutter" },
  // ];

  // Watch for changes in field
  const watchPhoneNumber = watch("phoneNumber");
  const watchProjects = watch("projects");
  const watchEducations = watch("educations");

  useEffect(() => {
    setProjects(watchProjects);
    setEducation(watchEducations);
  }, [watchProjects, watchEducations]);

  // Handle the onChange event of the PhoneInput component
  const handlePhoneChange = (value, data) => {
    setValue("phoneNumber", value);
    setValue("countryCode", `+${data?.dialCode}` || "");
  };

  // Define a function to generate the default values for the schema
  const getDefaultValues = () => {
    reset({
      profile: null,
      fullName: null,
      email: null,
      gender: "male",
      phoneNumber: null,
      currentCity: null,
      workStatus: "Fresher",
      dob: null,
      skills: [],
      resume: null,
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true); // Set loading to true on submission
    // console.log(data.profile, "Profile File");
    // console.log(data.resume, "Resume File");

    const token = localStorage.getItem("candidate_userId");
    const phoneNumberWithoutCode = data.phoneNumber.replace(
      `${data?.countryCode.slice(1)}`,
      ""
    );

    const { area, city, street, pincode, ...restData } = data;
    const address = {
      city: city,
      street: street,
      pincode: pincode,
      area: area,
    };

    const newData = {
      ...restData,
      address,
      phoneNumber: phoneNumberWithoutCode,
    };

    console.log(newData, "newData");
    console.log(data, "Data");
    // console.log(restData, "restData");

    // Compress the profile image
    const compressedProfileImage = await compressImage(data.profile);

    console.log(compressedProfileImage, "compressedProfileImage---");

    // Serialize the 'skills' array to a JSON string
    const skillsJSON = JSON.stringify(newData.skills);
    const speakingLanguagesJSON = JSON.stringify(newData.speakingLanguages);
    const addressJSON = JSON.stringify(newData.address);
    const projectsJSON = JSON.stringify(newData.projects);
    const educationsJSON = JSON.stringify(newData.educations);

    const formData = new FormData();

    // Append non-file data to formData
    Object.entries(newData).forEach(([key, value]) => {
      if (key === "profile") {
        // Append the compressed profile image
        formData.append(key, compressedProfileImage, "profile.jpg");
      } else if (key === "resume") {
        // Append the compressed resume PDF
        formData.append(key, data.resume, "resume.pdf");
      } else if (key === "skills") {
        // Append the JSON string
        formData.append(key, skillsJSON);
      } else if (key === "speakingLanguages") {
        // Append the JSON string
        formData.append(key, speakingLanguagesJSON);
      } else if (key === "address") {
        // Append the JSON string
        formData.append(key, addressJSON);
      } else if (key === "projects") {
        // Append the compressed resume PDF
        formData.append(key, projectsJSON);
      } else if (key === "educations") {
        // Append the compressed resume PDF
        formData.append(key, educationsJSON);
      } else {
        formData.append(key, value);
      }
    });

    Object.entries(data).forEach(([key, value]) => {
      console.log(key, value);
    });

    try {
      const response = await jobPortal.post(`candidate/updateData`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file upload
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response, "then");

      // Reset the state
      // getDefaultValues();
      setRegisterSuccess(true);
    } catch (error) {
      console.log(error);

      // Check if the error status is 401 (Unauthorized)
      if (error.response && error.response.status === 401) {
        // Clear the token from localStorage
        localStorage.removeItem("candidate_userId");

        // Redirect to the login page using useNavigate
        navigate("/job-portal/candidate/login");
      }
    } finally {
      setLoading(false); // Set loading to false after submission (success or error)
    }
  });

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          file,
          300, // Width
          300, // Height
          "JPEG", // Format
          60, // Reduced quality to achieve smaller file size
          0, // Rotation
          (uri) => {
            console.log(uri, "uri-----");
            resolve(uri);
          },
          "blob", // Output type
          300, // Max width (optional)
          300 // Max height (optional)
        );
      } catch (error) {
        console.log("error:", error);
        reject(error);
      }
    });
  };

  useEffect(() => {
    // Function to fetch candidate data based on id
    const fetchCandidateData = async () => {
      try {
        const token = localStorage.getItem("candidate_userId");
        const response = await jobPortal.get(`candidate/get/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const candidateData = response.data;

        console.log(candidateData, "candidateData--");

        if (candidateData.resume) {
          return navigate("/job-portal/candidate/profile");
        }

        // Set initial values for fullName and email
        setValue("fullName", candidateData.fullName);
        setValue("email", candidateData.email);
      } catch (error) {
        // Check if the error status is 401 (Unauthorized)
        if (error.response && error.response.status === 401) {
          // Clear the token from localStorage
          localStorage.removeItem("candidate_userId");

          // Redirect to the login page using useNavigate
          return navigate("/job-portal/candidate/login");
        }
      }
    };

    // Fetch candidate data when the component mounts
    fetchCandidateData();
  }, [id, setValue, navigate]);

  // useEffect(() => {
  //   console.log("Component rendered");
  //   console.log("Ref:", profileUploadInputRef.current);
  // }, []);

  // Function to handle profile image upload
  const handleProfileUpload = (files) => {
    // Check if a new file is selected
    if (files && files instanceof File) {
      const selectedFile = files;

      // Create a URL for the selected file
      const imageUrl = URL.createObjectURL(selectedFile);

      // Set the URL as the source of the image element
      const imageElement = document.getElementById("profileImage");

      if (imageElement) {
        imageElement.src = imageUrl;
      }
    }
    // No new file selected, do nothing
  };

  const handleAddProject = () => {
    console.log("handleAddProject");

    // Get the current value of the projects array
    const currentProjects = watch("projects");

    // Add a new project object to the array
    const newProject = {
      id: uuidv4(),
      projectName: "",
      projectDescription: "",
      projectStartYear: "",
      projectStartMonth: "",
      projectEndYear: "",
      projectEndMonth: "",
    };

    // Update the projects array with the new project
    setValue("projects", [...currentProjects, newProject]);
  };

  const handleDeleteProject = (project, index) => {
    const id = project.id;
    // console.log(index, "handleDeleteProject index");
    // console.log(project, "handleDeleteProject projects");
    // console.log(project.id, "handleDeleteProject projects");

    // Create a copy of the projects array
    const updatedProjects = projects.filter((project) => project.id !== id);

    // Update the projects array without the deleted project
    setValue("projects", updatedProjects);
  };

  const handleAddEducation = () => {
    console.log("handleAddEducation");

    // Get the current value of the educations array
    const currentEducations = watch("educations");

    // Add a new education object to the array
    const newEducation = {
      id: uuidv4(),
      qualification: "",
      // courseName: "",
      courseStartYear: "",
      courseEndYear: "",
      courseType: "",
    };

    // Update the Educations array with the new Education
    setValue("educations", [...currentEducations, newEducation]);
  };

  const handleDeleteEducation = (education, index) => {
    const id = education.id;

    // Create a copy of the Educations array
    const updatedEducations = educations.filter(education => education.id !== id);

    // Update the Educations array without the deleted Education
    setValue("educations", updatedEducations);
  };

  return (
    <>
      <Appbar />
      <Marquee
        pauseOnHover
        speed={40}
        style={{ backgroundColor: "#daf1ff", color: "white" }}
        className="py-1"
      >
        <img
          className="mx-4"
          src="https://www.react-fast-marquee.com/static/media/microsoft.4a9a93f0.png"
          style={{ maxWidth: "100px" }}
          alt=""
        />
        <img
          className="mx-4"
          src="https://www.react-fast-marquee.com/static/media/dell.09332c44.png"
          style={{ maxWidth: "100px" }}
          alt=""
        />
        <img
          className="mx-4"
          src="https://www.react-fast-marquee.com/static/media/microsoft.4a9a93f0.png"
          style={{ maxWidth: "100px" }}
          alt=""
        />
        <img
          className="mx-4"
          src="https://www.react-fast-marquee.com/static/media/microsoft.4a9a93f0.png"
          style={{ maxWidth: "100px" }}
          alt=""
        />
        <img
          className="mx-4"
          src="https://www.react-fast-marquee.com/static/media/microsoft.4a9a93f0.png"
          style={{ maxWidth: "100px" }}
          alt=""
        />
        <img
          className="mx-4"
          src="https://www.react-fast-marquee.com/static/media/microsoft.4a9a93f0.png"
          style={{ maxWidth: "100px" }}
          alt=""
        />
        <img
          className="mx-4"
          src="https://www.react-fast-marquee.com/static/media/microsoft.4a9a93f0.png"
          style={{ maxWidth: "100px" }}
          alt=""
        />
        <img
          className="mx-4"
          src="https://www.react-fast-marquee.com/static/media/microsoft.4a9a93f0.png"
          style={{ maxWidth: "100px" }}
          alt=""
        />
        <img
          className="mx-4"
          src="https://www.react-fast-marquee.com/static/media/microsoft.4a9a93f0.png"
          style={{ maxWidth: "100px" }}
          alt=""
        />
      </Marquee>

      {/* ------------- */}
      {/* {uploadPercent && (
        <section className="logo-loader">
          <div className="logo">
            <OALoaders />
          </div>
        </section>
      )} */}

      <main className="jobPortal-datacollect responsive-text container">
        <p className="f-12 mt-4">Fill up for first time</p>

        <main className="py-5 mainwrapper">
          {/* register-forms - start */}
          <main className="col register-forms">
            <section className="d-flex align-items-center mb-2">
              <div className="circle-avatar mr-2">
                <img
                  id="profileImage"
                  src={Avatar}
                  alt="img"
                  width="100%"
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div
                className="profile-upload-btn center fs-3"
                onClick={() => {
                  console.log("Button clicked");
                  console.log(profileUploadInputRef.current);
                  profileUploadInputRef.current.click();
                }}
              >
                Upload Profile
              </div>
              <Controller
                name="profile"
                {...register("profile")}
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      ref={profileUploadInputRef}
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];

                        if (selectedFile) {
                          // If a new file is selected, update the profile image
                          field.onChange(selectedFile);
                          handleProfileUpload(selectedFile);
                        } else {
                          // If no new file is selected (user canceled), do not update the profile image
                          console.log("User canceled file selection");
                        }
                      }}
                      hidden
                    />
                  </>
                )}
              />
            </section>
            <p className="text-red f-2">{errors.profile?.message}</p>
            <p className="f-8 fw-500 my-2">All Personal Informations</p>
            <section className="row col-700">
              <div className="col m-auto">
                <label className="f-4 mb-2" htmlFor="name">
                  Full Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  {...register("fullName")}
                />
                <p className="text-red f-2 mt-1">{errors.fullName?.message}</p>
              </div>
              <div className="col m-auto">
                <label className="f-5 mb-2" htmlFor="email">
                  Email Id <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  disabled // Add this line to make the input non-editable
                  type="email"
                  name="email"
                  id="email"
                  {...register("email")}
                />
                <p className="text-red f-2 mt-1">{errors.email?.message}</p>
              </div>
            </section>

            <section className="row col-700">
              <div className="col">
                <label className="f-5 mb-2" htmlFor="gender">
                  Gender <span style={{ color: "red" }}>*</span>
                </label>
                <select name="gender" {...register("gender")}>
                  <option value="" disabled selected hidden>Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                <p className="text-red f-2 mt-1">
                  {errors?.gender?.message}
                </p>
              </div>


              <div className="col">
                <label className="f-5 mb-2" htmlFor="DOB">
                  DOB <span style={{ color: "red" }}>*</span>
                </label>

                {/* <input type="date" name="dob" {...register("dob")} /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="dob"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        // label="Date of Birth"
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) => {
                          field.onChange(date);
                          console.log(date, "date on change");
                        }}
                        renderInput={(startProps) => (
                          <TextField {...startProps} variant="standard" />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>

                <ErrorMessage
                  errors={errors}
                  name="dob"
                  render={() => (
                    <p className="text-red f-2 mt-1">
                      Date of birth is required
                    </p>
                  )}
                />
              </div>
            </section>

            <section className="row col-700">
              <div className="col">
                <label className="f-5 mb-2" htmlFor="phone-number">
                  Phone Number(Whats App){" "}
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                        autoFocus: true,
                      }}
                      country={"in"}
                      placeholder="Enter phone number"
                      value={watchPhoneNumber}
                      onChange={(value, data) => {
                        field.onChange(value); // This line is important for react-hook-form
                        handlePhoneChange(value, data);
                      }}
                    />
                  )}
                />
                <p className="text-red f-2 mt-1">
                  {errors.phoneNumber?.message}
                </p>
              </div>
              <div className="col">
                <label className="f-5 mb-2" htmlFor="linkedIn">
                  Linked In
                </label>
                <input
                  placeholder="eg. oceanacademy.in"
                  type="text"
                  id="linkedIn"
                  name="linkedIn"
                  {...register("linkedIn")}
                />
                <p className="text-red f-2 mt-1">{errors.linkedIn?.message}</p>
              </div>
            </section>

            <div className="col my-2">
              <label className="f-5 mb-2" htmlFor="speakingLanguages">
                Speaking Languages
                <span style={{ color: "red" }}> *</span>
              </label>
              <Controller
                name="speakingLanguages"
                control={control}
                render={({ field }) => (
                  <Select
                    className="basic-multi-select f-4"
                    isMulti
                    value={speakingLanguages.filter((option) =>
                      field.value?.includes(option.value)
                    )} // Pre-select the values
                    onChange={(selectedOptions) =>
                      field.onChange(
                        selectedOptions.map((option) => option.value)
                      )
                    }
                    options={speakingLanguages}
                    classNamePrefix="select"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        height: "45px", // Adjust the height as needed
                      }),
                      // Add more styles as needed
                    }}
                  />
                )}
                {...register("speakingLanguages")}
              />

              <ErrorMessage
                errors={errors}
                name="speakingLanguages"
                render={() => (
                  <p className="text-red f-2 mt-1">
                    Speaking Languages is required
                  </p>
                )}
              />
            </div>

            <p className="f-8 fw-500 my-2">Address</p>

            <section className="row col-700">
              <div className="col">
                <label className="f-5 mb-2" htmlFor="area">
                  Area <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="eg. Saram"
                  type="text"
                  id="area"
                  name="area"
                  {...register("area")}
                />
                <p className="text-red f-2 mt-1">{errors.area?.message}</p>
              </div>

              <div className="col">
                <label className="f-5 mb-2" htmlFor="street">
                  Street <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="eg. Vengateshwara Nagar"
                  type="text"
                  id="street"
                  name="street"
                  {...register("street")}
                />
                <p className="text-red f-2 mt-1">{errors.street?.message}</p>
              </div>
            </section>

            <section className="row col-700">
              <div className="col">
                <label className="f-5 mb-2" htmlFor="pincode">
                  Pincode <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="eg. 605001"
                  type="number"
                  id="pincode"
                  name="pincode"
                  {...register("pincode")}
                />
                <p className="text-red f-2 mt-1">{errors.pincode?.message}</p>
              </div>

              <div className="col">
                <label className="f-5 mb-2" htmlFor="city">
                  City <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="eg. Pondicherry"
                  type="text"
                  id="city"
                  name="city"
                  {...register("city")}
                />
                <p className="text-red f-2 mt-1">{errors.city?.message}</p>
              </div>
            </section>

            {watchEducations.map((education, index) => (
              <main key={index}>
                <p className="f-8 fw-500 d-flex justify-content-between my-2">
                  <span>Education {index + 1}</span>
                  <span className="d-flex align-items-center pe-auto" onClick={() => handleDeleteEducation(education, index)}><AiOutlineCloseSquare className="f-10 text-red" /></span>
                </p>
                <section className="row col-700">
                  <div className="col">
                    <label className="f-5 mb-2" htmlFor={`projectName_${index}`}>
                      Qualification/Degree
                    </label>
                    <input
                      className="qualification"
                      placeholder="eg. BTech"
                      type="text"
                      id={`qualification_${index}`}
                      name={`educations[${index}].qualification`}
                      {...register(`educations[${index}].qualification`)}
                    />
                    <p className="text-red f-2 mt-1">
                      {errors?.projects?.[index]?.projectStartYear?.message}
                    </p>
                  </div>
                </section>

                {/* <div className="textarea-wrapper flex-col">
                  <label className="f-5 mb-2" htmlFor={`projectDescription_${index}`}>
                    Course Name
                  </label>
                  <input
                    className="courseName"
                    placeholder="eg. CMS Project"
                    type="text"
                    id={`projectName_${index}`}
                    name={`educations[${index}].projectName`}
                    {...register(`educations[${index}].projectName`)}
                  />
                  <p className="text-red f-2 mt-1">
                    {errors?.educations?.[index]?.courseName?.message}
                  </p>
                </div> */}


                <div className="textarea-wrapper flex-col">
                  <label className="f-5 mb-2" htmlFor={`organizationName_${index}`}>
                    Collage/School Name
                  </label>
                  <input
                    className="organizationName"
                    placeholder="eg. Smvc"
                    type="text"
                    id={`organizationName_${index}`}
                    name={`educations[${index}].organizationName`}
                    {...register(`educations[${index}].organizationName`)}
                  />
                  <p className="text-red f-2 mt-1">
                    {errors?.educations?.[index]?.organizationName?.message}
                  </p>
                </div>

                <section className="row col-700 align-items-center">
                  <div className="col">
                    <label className="f-5 mb-2" htmlFor={`courseStartYear_${index}`}>
                      Course Started Year
                    </label>
                    <select
                      type="number"
                      id={`courseStartYear_${index}`}
                      name={`educations[${index}].courseStartYear`}
                      {...register(`educations[${index}].courseStartYear`)}
                    >
                      <option value="" disabled selected hidden>Select Start year</option>
                      {yearsArray.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <p className="text-red f-2 mt-1">
                      {errors?.educations?.[index]?.courseStartYear?.message}
                    </p>
                  </div>

                  <span className="f-4 mt-2">to</span>

                  <div className="col">
                    <label className="f-5 mb-2" htmlFor={`courseEndYear_${index}`}>
                      Course End Year
                    </label>
                    <select
                      type="text"
                      id={`courseEndYear_${index}`}
                      name={`educations[${index}].courseEndYear`}
                      {...register(`educations[${index}].courseEndYear`)}
                    >
                      <option value="" disabled selected hidden>Select End Year</option>
                      {yearsArray.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <p className="text-red f-2 mt-1">
                      {errors?.educations?.[index]?.courseEndYear?.message}
                    </p>
                  </div>
                </section>

                <div className="col">
                  <label className="f-5 mb-2" htmlFor={`courseType_${index}`}>
                    Course type
                  </label>
                  <select
                    type="number"
                    id={`courseType_${index}`}
                    name={`educations[${index}].courseType`}
                    {...register(`educations[${index}].courseType`)}
                  >
                    <option value="" disabled selected hidden>Select Course Type</option>
                    {courseTypesArray.map((courseType) => (
                      <option key={courseType} value={courseType}>
                        {courseType}
                      </option>
                    ))}
                  </select>
                  <p className="text-red f-2 mt-1">
                    {errors?.educations?.[index]?.courseType?.message}
                  </p>
                </div>
              </main>
            ))}

            <p className="f-5 fw-500 my-2 pe-auto" onClick={handleAddEducation}>{watchEducations.length > 0 ? "+ Add Another educations" : "+ Add educations"}</p>

            <hr className="my-2" />

            <p className="f-8 fw-500 my-2">About</p>

            <section className="col radio my-2">
              <label className="f-5 mb-2" htmlFor="workStatus">
                Work Status <span style={{ color: "red" }}>*</span>
              </label>

              <div className="row">
                <div className="d-flex workstatus-btn-wrapper">
                  <input
                    type="radio"
                    className="mr-2"
                    name="workStatus"
                    id="experienced"
                    value="Experienced"
                    {...register("workStatus")}
                  />
                  <label
                    className="workstatus-btn fs-em-3 center"
                    htmlFor="experienced"
                  >
                    I’m Experienced
                  </label>
                </div>

                <div className="d-flex workstatus-btn-wrapper">
                  <input
                    type="radio"
                    className="mx-3"
                    name="workStatus"
                    id="fresher"
                    value="Fresher"
                    {...register("workStatus")}
                    defaultChecked
                  />
                  <label
                    className="workstatus-btn fs-em-3 center"
                    htmlFor="fresher"
                  >
                    I’m a Fresher
                  </label>
                </div>
              </div>
            </section>

            <div className="col my-2">
              <label className="f-5 mb-2" htmlFor="skills">
                Skills
                <span style={{ color: "red" }}> *</span>
              </label>
              <Controller
                name="skills"
                control={control}
                render={({ field }) => (
                  <Select
                    className="basic-multi-select f-4"
                    isMulti
                    value={IT_SKILLS.filter((option) =>
                      field.value?.includes(option.value)
                    )} // Pre-select the values
                    onChange={(selectedOptions) =>
                      field.onChange(
                        selectedOptions.map((option) => option.value)
                      )
                    }
                    options={IT_SKILLS}
                    classNamePrefix="select"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        height: "45px", // Adjust the height as needed
                      }),
                      // Add more styles as needed
                    }}
                  />
                )}
                {...register("skills")}
              />

              <ErrorMessage
                errors={errors}
                name="skills"
                render={() => (
                  <p className="text-red f-2 mt-1">Skills is required</p>
                )}
              />
            </div>

            <section className="col my-2">
              <div className="textarea-wrapper flex-col">
                <label className="f-5 mb-2" htmlFor="profileSummary">
                  Profile Summary
                  <span style={{ color: "red" }}> *</span>
                </label>
                <textarea
                  className="f-5 p-2"
                  rows="10"
                  cols=""
                  placeholder="Briefly summarize your professional profile"
                  name="profileSummary"
                  {...register("profileSummary")}
                ></textarea>
                <p className="text-red f-2 mt-1">
                  {errors.profileSummary?.message}
                </p>
              </div>
            </section>

            <hr className="my-3" />
            <p className="f-8 fw-500 my-2">Your Career Preferences</p>

            {/* <section className="row col-700">
              <div className="col m-auto">
                <label className="f-4 mb-2" htmlFor="name">
                  Project Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  {...register("fullName")}
                />
                <p className="text-red f-2 mt-1">{errors.fullName?.message}</p>
              </div>
              <div className="col m-auto">
                <label className="f-5 mb-2" htmlFor="email">
                  Project Description
                </label>
                <input
                  disabled // Add this line to make the input non-editable
                  type="email"
                  name="email"
                  id="email"
                  {...register("email")}
                />
                <p className="text-red f-2 mt-1">{errors.email?.message}</p>
              </div>
            </section>  */}

            <div className="col my-2">
              <label className="f-5 mb-2">
                Resume <span style={{ color: "red" }}>*</span>
              </label>
              <Controller
                type="file"
                name="resume"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <>
                    <input
                      className="my-3"
                      id="resume"
                      type="file"
                      accept=".pdf"
                      onChange={(e) => {
                        // console.log(e.target.files[0], "e.target.files");
                        field.onChange(e.target.files[0]);
                      }}
                    />
                    {errors.pdfFile && <p>{errors.pdfFile.message}</p>}
                  </>
                )}
              ></Controller>
              <article className="flex-row align-items-center">
                <label
                  htmlFor="resume"
                  className="resume-btn center text-center fs-2 clr-light"
                >
                  Upload Resume
                </label>
                <label
                  htmlFor="resume"
                  className="resume-btn-details flex-row align-items-center fs-2 py-2"
                >
                  Upload PDF
                </label>
              </article>
              {/* <ErrorMessage
                errors={errors}
                name="resume"
                render={({ message }) => (
                  <p
                    style={{
                      color: "red",
                      fontSize: "15px",
                      paddingBottom: "10px",
                    }}
                  >
                    {message}
                  </p>
                )}
              /> */}
              <p className="text-red f-2 mt-1">{errors.resume?.message}</p>
            </div>

            <div className="col">
              <label className="f-5 mb-2" htmlFor="noticePeriod">
                Notice Period
              </label>
              <select name="noticePeriod" {...register("noticePeriod")}>
                <option value="" disabled selected hidden>Select</option>
                <option value="Immediate Joiner">Immediate Joiner</option>
                <option value="1 Month">1 month</option>
                <option value="2 Month">2 Month</option>
                <option value="3 Month">3 Month</option>
                <option value="4 Month">4 Month</option>
                <option value="5 Month">5 Month</option>
                <option value="6 Month">6 Month</option>
              </select>
              <p className="text-red f-2 mt-1">
                {errors.noticePeriod?.message}
              </p>
            </div>

            <div className="col my-3">
              <label className="f-5 mb-2" htmlFor="salaryExceptation">
                Salary Expectation(LPA in Rupees){" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                placeholder="eg. 400000"
                type="number"
                name="salaryExpectation"
                id="salaryExpectation"
                {...register("salaryExpectation")}
              />
              <p className="text-red f-2 mt-1">
                {errors.salaryExpectation?.message}
              </p>
            </div>

            {watchProjects.map((project, index) => (
              <main key={index}>
                <p className="f-8 fw-500 d-flex justify-content-between my-2">
                  <span>Projects {index + 1}</span>
                  <span
                    className="d-flex align-items-center pe-auto"
                    onClick={() => handleDeleteProject(project, index)}
                  >
                    <AiOutlineCloseSquare className="f-10 text-red" />
                  </span>
                </p>
                {/* <p className="f-6 fw-400 my-1">Project {index + 1}</p> */}
                <section className="row col-700">
                  <div className="col">
                    <label
                      className="f-5 mb-2"
                      htmlFor={`projectName_${index}`}
                    >
                      Project Name
                    </label>
                    <input
                      className="projectName"
                      placeholder="eg. CMS Project"
                      type="text"
                      id={`projectName_${index}`}
                      name={`projects[${index}].projectName`}
                      {...register(`projects[${index}].projectName`)}
                    />
                    <p className="text-red f-2 mt-1">
                      {errors?.projects?.[index]?.projectName?.message}
                    </p>
                  </div>
                </section>

                <div className="textarea-wrapper flex-col">
                  <label
                    className="f-5 mb-2"
                    htmlFor={`projectDescription_${index}`}
                  >
                    Project Description
                  </label>
                  <textarea
                    className="f-5 p-2"
                    rows="8"
                    cols=""
                    placeholder="Briefly summarize your professional profile"
                    name={`projects[${index}].projectDescription`}
                    {...register(`projects[${index}].projectDescription`)}
                  ></textarea>
                  <p className="text-red f-2 mt-1">
                    {errors?.projects?.[index]?.projectDescription?.message}
                  </p>
                </div>

                <section className="row col-700">
                  <div className="col">
                    <label
                      className="f-5 mb-2"
                      htmlFor={`projectStartYear_${index}`}
                    >
                      Project Started Year
                    </label>
                    <select
                      type="number"
                      id={`projectStartYear_${index}`}
                      name={`projects[${index}].projectStartYear`}
                      {...register(`projects[${index}].projectStartYear`)}
                    >
                      <option value="" disabled selected hidden>
                        Select year
                      </option>
                      {yearsArray.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <p className="text-red f-2 mt-1">
                      {errors?.projects?.[index]?.projectStartYear?.message}
                    </p>
                  </div>

                  <div className="col">
                    <label
                      className="f-5 mb-2"
                      htmlFor={`projectStartMonth_${index}`}
                    >
                      Project Started Month
                    </label>
                    <select
                      type="text"
                      id={`projectStartMonth_${index}`}
                      name={`projects[${index}].projectStartMonth`}
                      {...register(`projects[${index}].projectStartMonth`)}
                    >
                      <option value="" disabled selected hidden>
                        Select month
                      </option>
                      {monthsArray.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <p className="text-red f-2 mt-1">
                      {errors?.projects?.[index]?.projectStartMonth?.message}
                    </p>
                  </div>
                </section>

                <section className="row col-700">
                  <div className="col">
                    <label
                      className="f-5 mb-2"
                      htmlFor={`projectEndYear_${index}`}
                    >
                      Project End Year
                    </label>
                    <select
                      type="number"
                      id={`projectEndYear_${index}`}
                      name={`projects[${index}].projectEndYear`}
                      {...register(`projects[${index}].projectEndYear`)}
                    >
                      <option value="" disabled selected hidden>
                        Select year
                      </option>
                      {yearsArray.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <p className="text-red f-2 mt-1">
                      {errors?.projects?.[index]?.projectEndYear?.message}
                    </p>
                  </div>

                  <div className="col">
                    <label
                      className="f-5 mb-2"
                      htmlFor={`projectEndMonth_${index}`}
                    >
                      Project End Month
                    </label>
                    <select
                      type="text"
                      id={`projectEndMonth_${index}`}
                      name={`projects[${index}].projectEndMonth`}
                      {...register(`projects[${index}].projectEndMonth`)}
                    >
                      <option value="" disabled selected hidden>
                        Select month
                      </option>
                      {monthsArray.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <p className="text-red f-2 mt-1">
                      {errors?.projects?.[index]?.projectEndMonth?.message}
                    </p>
                  </div>
                </section>
              </main>
            ))}

            <p className="f-5 fw-500 my-2 pe-auto" onClick={handleAddProject}>
              {watchProjects.length > 0
                ? "+ Add Another projects"
                : "+ Add projects"}
            </p>

            {/* <div className="d-flex my-1">
              <input className="mr-1" type="checkbox" id="declaration" />
              <label className="fs-em-3" htmlFor="declaration">
                By clicking Register, you agree to the
                <span className="clr-success fw-500 pe-auto">
                  &nbsp;Terms and Conditions&nbsp;
                </span>
                &
                <span className="clr-success fw-500 pe-auto">
                  &nbsp;Privacy Policy&nbsp;
                </span>
                of oceanacademy.co.in
              </label>
            </div> */}

            <div
              className={`register-btn f-4 center my-1 ${loading ? "disabled" : "diagonal-close"
                }`}
              // Disable the onClick event when loading
              onClick={loading ? null : onSubmit}
            >
              {loading ? (
                <span className="text-center">Loading...</span>
              ) : (
                <span className="text-center">Continue</span>
              )}
            </div>
          </main>
          {/* register-forms - end */}

          {/* notice - start */}
          <main className="notice">
            <div className="card col">
              <img
                src="https://cdna.artstation.com/p/assets/images/images/034/457/374/large/shin-min-jeong-.jpg?1612345113"
                alt=""
              />
              <p className="fs-em-7 fw-500 mt-8">On registering, you can</p>
              <div className="flex-row f-4 mt-3">
                <span className="flex-col mr-1">
                  <TiTick className="clr-success fs-em-6" />
                </span>
                <span className="flex-col">
                  Build your profile and let recruiters find you.
                </span>
              </div>
              <div className="flex-row f-4 mt-3">
                <span className="flex-col mr-1">
                  <TiTick className="clr-success fs-em-6" />
                </span>
                <span className="flex-col">
                  Get job postings delivered right to your email.
                </span>
              </div>
              <div className="flex-row f-4 my-3">
                <span className="flex-col mr-1">
                  <TiTick className="clr-success fs-em-6" />
                </span>
                <span className="flex-col">
                  Please upload your genuine resume so what recruiters will pick
                  you soon.
                </span>
              </div>
            </div>
          </main>
          {/* notice - end */}
        </main>

        <SuccessConfirmation
          state={[isRegisterSuccess, setRegisterSuccess]}
          navigateScreen={() => navigate(`/job-portal/candidate/profile`)}
        >
          <Typography
            color="green"
            gutterBottom
            style={{ fontSize: "2rem", fontWeight: "bolder" }}
          >
            Updated your Profile Successful 😊!
          </Typography>
          <Typography
            gutterBottom
            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            Welcome to Ocean Academy Job Portal!
          </Typography>
          <Typography gutterBottom style={{ fontSize: "1.5rem" }}>
            Thank you for completing your profile. You are now part of our
            community.
          </Typography>
          <Typography gutterBottom style={{ fontSize: "1.5rem" }}>
            Your profile information has been successfully saved.
          </Typography>
          <Typography gutterBottom style={{ fontSize: "1.5rem" }}>
            You can now expect job opportunities, manage your profile, and stay
            connected with us.
          </Typography>
          <Typography gutterBottom style={{ fontSize: "1.5rem" }}>
            If you have any questions or need assistance, feel free to contact
            our support team.
          </Typography>
        </SuccessConfirmation>
      </main>
    </>
  );
}
