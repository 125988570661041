import React, { useEffect, useState } from 'react'
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { JPCL } from '../JP-Candidate/JPCL/JPCL';
import { jobPortal } from '../../..';
// import { JPCProfileEdit } from '../JP-Candidate/JPCP/JPCPE';
// import { JPCVerified } from '../JP-Candidate/JPC-Verify/JPCVerified';
// import { JPCR } from '../JP-Candidate/JPCR/JPCR';
// import { JPCL } from '../JP-Candidate/JPCL/JPCL';
// import { JPCRDataCollect } from '../JP-Candidate/JPCR-DataCollect/JPCRDataCollect';
// import { JPCP } from '../JP-Candidate/JPCP/JPCP';


export const CandidateAuthorization = () => {
    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem("candidate_userId"));


    useEffect(() => {
        setToken(localStorage.getItem("candidate_userId"));
        console.log("app useEffect token", token);

        // Get the current location pathname
        const currentPathname = window.location.pathname;

        if (token && currentPathname === "/job-portal/candidate") {
            // If token exists and current location is the root, navigate to the "profile" route
            navigate("profile");
        }

    }, [token, navigate]);




    console.log("called");

    return (
        token ? <Outlet /> : <Navigate to="login" />
    )
}

