import { AiOutlineMenuUnfold } from "react-icons/ai";
// import { MdNotifications } from "react-icons/md";
import { RiUserSearchFill } from "react-icons/ri";
import { useRecruiterReactQuery } from "../../utils/RecruiterDataContext";
// import { VscSymbolColor } from "react-icons/vsc";

export function Header() {
  const { data } = useRecruiterReactQuery();

  const { recruiter = {} } = data || {};

  const openSidebar = () => {
    const doc = document;
    doc.querySelector(".sidebar").className = "sidebar-active";
  };

  return (
    <nav className="d-flex justify-content-between align-items-center px-3 py-1">
      <section className="d-flex align-items-center f-7">
        <AiOutlineMenuUnfold
          className="menu-icon f-10 pe-auto d-none mr-2"
          onClick={openSidebar}
        />
        <RiUserSearchFill />
        <span className="ml-1 fw-bold">OA Recruiter Portal</span>
      </section>
      <section className="d-flex align-items-center">
        {/* <VscSymbolColor className="theme-icon f-14 pe-auto ml-3" />
        <MdNotifications className="notify-icon f-14 pe-auto ml-3" /> */}
        <article className="avatar center ml-1">
          {recruiter && recruiter.fullName && recruiter.fullName[0] ? recruiter.fullName[0] : ""}
        </article>
        <div className="fullname ml-1 f-6">{recruiter.fullName}</div>
      </section>
    </nav>
  );
}
