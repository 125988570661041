import { Link, useNavigate, useParams } from "react-router-dom";
import "./_candidate_forgetpassword.scss";
import Rectangles from "../../../Image/rectangles.svg";
import CirclePattern from "../../../Image/circlepattern.svg";
import { Appbar } from "../../../Appbar/Appbar";
// import { useEffect } from "react";
import { jobPortal } from "../../../..";
import { useEffect, useState } from "react";
// import { encryptData } from "../../common/encrypt";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateNewPassword, LoginSchema } from "../Validation/validation";
import { Alert, AlertTitle, Typography } from "@mui/material";
import { CANDIDATE_TOKEN_NAME } from "../../common/constant";
import { useQuery } from "react-query";
import { MdArrowBack } from "react-icons/md";
import { SuccessConfirmation } from "../../Components/SuccessConfirmation";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// JOB PORTAL Candidate New Password
export function CandidateNewPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [isForgetPasswordSuccess, setForgetPasswordSuccess] = useState(false);
  const [verify, setVerify] = useState(false);
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({ resolver: yupResolver(CreateNewPassword) });

  const { data, error, isSuccess, isError, isLoading, isFetched } = useQuery(
    "candidate-password-reset-verification",
    async () => {
      try {
        const response = await jobPortal.post(
          `candidate/reset-password-verify`,
          { token }
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching candidate data:", error);
        if (error.response.status === 403) {
          console.log("Already resetted password");
          setVerify(true);
        }
        // throw error; // Rethrow the error to be caught in the error handling block
      }
    }
    // { staleTime: 5 * 60 * 1000 } // 5 minutes in milliseconds
  );

  // if (isError) {
  //   console.log(error.response.status);
  //   if (error.response.status === 403) {
  //     console.log("yes");
  //   }
  // }

  console.log(data, "data-------");
  // console.log(error, "error-------");
  // console.log(isError, "isError-------");
  // console.log(isSuccess, "isSuccess-------");

  const onSubmit = handleSubmit(async (data) => {
    const newDate = {
      token: token,
      newPassword: data.newPassword,
    };
    console.log(newDate, "data----");
    try {
      setLoading(true); // Set loading to true on submission
      const response = await jobPortal.post(
        "candidate/reset-password",
        newDate
      );

      console.log(response, "response");

      setLoading(false); // Set loading to false after submission

      console.log(response.data, "data received");

      // // Reset the form
      reset({
        password: "",
        confirmPassword: "",
      });


      setForgetPasswordSuccess(true);

      setVerify(true);
    } catch (error) {
      console.error("API request failed:", error.response);
      // Log the entire error object
      console.log(error);
      setErrorMessage(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after submission (success or error)
    }
  });

  useEffect(() => {
    // Automatically clear the error message after 3000 milliseconds (5 seconds)
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  }, [errorMessage]);

  useEffect(() => {
    const token = localStorage.getItem(CANDIDATE_TOKEN_NAME);
    if (token) {
      navigate("/job-portal/candidate/profile");
    }
  }, [navigate]);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmShowPassword = () => {
    setConfirmShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Appbar />
      {verify ? (
        <main className="flex-col container center f-4">
          <img src="https://cdn.dribbble.com/users/2382015/screenshots/6065978/no_result.gif" />
          {/* <p>Sorry, this page isn't available.</p>
          <p>
            The link you followed may be broken, or the page may have been
            removed. Go back to oceanacademy.co.in/job-portal.
          </p> */}
          <Link to="/job-portal/candidate/login">Go to login</Link>
        </main>
      ) : (
        <main className="candidate-forgetpassword container center">
          <img
            src={Rectangles}
            alt="not found"
            style={{ position: "fixed", left: "0px", zIndex: "-1" }}
          />

          <img
            src={CirclePattern}
            alt="not found"
            style={{
              position: "fixed",
              right: "0px",
              top: "0px",
              zIndex: "-1",
            }}
          />
          <section className="login-card flex-col">
            <article className="text-center f-9">
              Hello {data?.fullName} &#128075;
            </article>
            <article className="my-1 text-center f-3">
              Please create a new Strong Password and this valid upto only 24
              hours so create as soon as possible
            </article>

            {errorMessage && (
              <Alert
                severity="error"
                style={{ width: "100%", fontSize: "1.2rem" }}
              >
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            )}
            <div className="password-input">
              <input
                id="newPassword"
                type={showPassword ? "text" : "password"}
                name="newPassword"
                placeholder="New Password"
                {...register("newPassword")}
              />
              {showPassword ? <FaEyeSlash className="eye-toggle" onClick={toggleShowPassword} /> : <FaEye className="eye-toggle" onClick={toggleShowPassword} />}
            </div>
            <p className="warning f-2 my-1">{errors.newPassword?.message}</p>
            <div className="password-input">
              <input
                id="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm Password"
                {...register("confirmPassword")}
              />
              {showConfirmPassword ? <FaEyeSlash className="eye-toggle" onClick={toggleConfirmShowPassword} /> : <FaEye className="eye-toggle" onClick={toggleConfirmShowPassword} />}
            </div>
            <p className="warning f-2 my-1">
              {errors.confirmPassword?.message}
            </p>
            <div
              className={`login-btn center my-2 ${loading ? "disabled" : "diagonal-close"
                }`}
              // Disable the onClick event when loading
              onClick={loading ? null : onSubmit}
            >
              {loading ? <span>Loading...</span> : <span>Reset Password</span>}
            </div>
            <div className="or m-auto">or</div>
            <p
              className="d-flex align-items-center m-auto text-center pe-auto"
              onClick={() => navigate(-1)}
            >
              <MdArrowBack className="mr-1" />
              Back to Login In
            </p>
          </section>
        </main>
      )}
      <SuccessConfirmation
        state={[isForgetPasswordSuccess, setForgetPasswordSuccess]}
        navigateScreen={() => navigate("/job-portal/candidate/login")}
      >
        <Typography
          color="green"
          gutterBottom
          style={{ fontSize: "2rem", fontWeight: "bolder" }}
        >
          Password Reset Successful 😊
        </Typography>
        <Typography
          gutterBottom
          style={{ fontSize: "1.5rem", fontWeight: "bold" }}
        >
          Your password has been successfully reset.
        </Typography>
        <Typography gutterBottom style={{ fontSize: "14px" }}>
          You can now log in with your new password.
        </Typography>
        <Typography gutterBottom style={{ fontSize: "14px" }}>
          If you have any concerns or need further assistance, please reach out to our support team.
        </Typography>
      </SuccessConfirmation>
    </>
  );
}
